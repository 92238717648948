// hooks
import moment from 'moment';
import { useState, useEffect } from 'react';

// @fullcalendar
// import { Draggable } from '@fullcalendar/interaction';

export default function useScheduleHanlders(defaultEvents, setModalController, getSchedule, filters) {
  const [events, setEvents] = useState(defaultEvents);

  useEffect(() => {
    if (defaultEvents.length > 0) {
      const newDefaultEvents = defaultEvents.map(event => {
        const hasArrived = event.arrival_time != null && event.departure_time !== null;
        const statusTable = {
          waiting: '#d3a332',
          attended: '#15ab72',
          canceled: '#ad0303',
          didnotattend: '#77ACD8',
        };

        return {
          ...event,
          title: `${event.patient_name ?? 'Paciente'} > ${event.procedure_name ?? 'Procedimento'}`,
          start: hasArrived ? new Date(event.arrival_time) : new Date(event.schedule_date),
          end: hasArrived ? new Date(event.departure_time) : new Date(event.schedule_date_end ?? event.schedule_date),
          color: statusTable[event.status],
        };
      });
      setEvents(newDefaultEvents);
    } else {
      setEvents([]);
    }
  }, [defaultEvents]);

  // transforma os elemtos de fora da agenda em draggable elements
  // useEffect(() => {
  //   let draggableEl = document.getElementById('external-events');
  //   new Draggable(draggableEl, {
  //     itemSelector: '.external-event',
  //   });
  // }, []);

  function onDateClick(arg) {
    let selectedDateTime;
    if (arg.dateStr && arg.dateStr.includes('T')) {
      selectedDateTime = moment(arg.dateStr);
    } else {
      selectedDateTime = moment();
    }
    const professionalId = arg.resource?.id;
    setModalController(prevState => ({
      ...prevState,
      open: true,
      event: 'add',
      eventData: {
        user: professionalId ? parseInt(professionalId) : null,
        schedule_date: selectedDateTime.format('YYYY-MM-DD'),
        hour: selectedDateTime.format('HH:mm'),
        hour_end: selectedDateTime.add(30, 'minutes').format('HH:mm'),
      },
    }));
  }

  const onEventClick = async (arg) => {
    const scheduleId = parseInt(arg.event.id);
    const scheduleData = await getSchedule(scheduleId);
    setModalController({
      open: true,
      event: 'view',
      eventData: scheduleData,
    });
  }

  // função para reorganizar os eventos ao arrastar e soltar (drag and drop)
  function onDrop(arg) {
    // const dropEventData = arg;
    // const title = dropEventData.draggedEl.title;
    // if (title == null) {
    // } else {
    //   let newEvent = {
    //     id: String(events.length + 1),
    //     title: title,
    //     start: dropEventData ? dropEventData.dateStr : new Date(),
    //     className: dropEventData.draggedEl.attributes.getNamedItem('data-class')?.value,
    //   };
    //   const modifiedEvents = [...events];
    //   modifiedEvents.push(newEvent);
    //   setEvents(modifiedEvents);
    // }
  }

  function onEventDrop(arg) {
    // const modifiedEvents = [...events];
    // const idx = modifiedEvents.findIndex(e => String(e['id']) === String(arg.event.id));
    // modifiedEvents[idx]['title'] = arg.event.title;
    // modifiedEvents[idx]['className'] = arg.event.classNames;
    // modifiedEvents[idx]['start'] = arg.event.start;
    // modifiedEvents[idx]['end'] = arg.event.end;
    // setEvents(modifiedEvents);
    // setIsEditable(false);
  }

  return { events, onDateClick, onEventClick, onDrop, onEventDrop };
}
