import React, { useContext, useEffect, useState } from 'react';
import styles from './MyProfile.module.scss';
import { validateUserData, validateUserPassword } from './validator';
import Input from 'components/Input/Input';
import { Grid } from '@mui/material';
import { handleSelectOneFile } from 'shared/utility';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Button from 'components/Button/Button';
import { UserRepository } from 'data/users';
import { AuthContext } from 'contexts/auth';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';

function MyProfile() {
  const { loading, setLoading } = useContext(LoaderContext);
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    photo: null,
    photo64: null,
  });
  const [passwordError, setPasswordError] = useState({});
  const [passwordForm, setPaswordForm] = useState({
    current_password: '',
    new_password: '',
    new_password2: '',
  });

  useEffect(() => {
    setForm({
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
      photo: user.photo,
    });
  }, [user]);
  
  async function validateForm() {
    try {
      await validateUserData(form);
      setError({});
      return true;
    } catch (error) {
      const newError = {};
      error.inner.forEach(e => newError[e.path] = e.message);
      setError(newError);
    }
    return false;
  }
  
  async function validatePassword() {
    try {
      await validateUserPassword(passwordForm);
    } catch (error) {
      const newError = {};
      error.inner.forEach(e => newError[e.path] = e.message);
      setPasswordError(newError);
      return false;
    }

    if (passwordForm.new_password !== passwordForm.new_password2) {
      setPasswordError({ new_password2: 'Senhas não conferem' });
      return false;
    }
    
    setPasswordError({});
    return true;    
  }
  
  async function saveUserPassword() {
    if (!await validatePassword()) return;
    setLoading(true);
    const response = await UserRepository.updateUserPassword(user.id, user.email, passwordForm);
    setLoading(false);
    if (response && response.error) {
      setPasswordError(response.error);
    } else {
      toast.success('Senha alterada com sucesso!');
    }
  }

  async function saveUser() {
    if (!await validateForm()) return;
    setLoading(true);
    const response = await UserRepository.updateUserData(user.id, { ...form });
    setLoading(false);
    if (response && response.error) {
      toast.error(response.error);
    } else {
      toast.success('Dados salvos com sucesso!');
      if (form.photo64) {
        window.location.reload();
      }
    }
  }
   
  function handleInputChange(fieldName, event) {
    setForm(prevState => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  }
   
  function handleInputPasswordChange(fieldName, event) {
    setPaswordForm(prevState => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.form_container}>
          <h2 className={styles.title}>Informações Pessoais</h2>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="Nome"
                value={form.first_name}
                onChange={event => handleInputChange('first_name', event)}
                error={error?.first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Sobrenome"
                value={form.last_name}
                onChange={event => handleInputChange('last_name', event)}
                error={error?.last_name}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Telefone (Whatsapp)"
                mask="(99)99999-9999"
                addMask
                lower
                value={form.phone_number}
                error={error?.phone_number}
                onChange={event => handleInputChange('phone_number', event)}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <div
            className={styles.profile_picture_container}
            onClick={() => {
              handleSelectOneFile('.jpg, .jpeg, .png', (photo, photo64) => {
                setForm({ ...form, photo, photo64 });
              });
            }}
          >
            <UserAvatar size={100} imageUrl={form.photo64 ?? form.photo} className={styles.picture} />
            <span>{form.photo != null ? 'Editar imagem' : 'Adicionar imagem'}</span>
          </div>
        </div>
      </div>
      <Button
        className={styles.saveButton}
        disabled={loading}
        color="secondary"
        label="Salvar"
        size="large"
        onClick={saveUser}
      />
      <div className={styles.container}>
        <div className={styles.form_container}>
          <h2 className={styles.title}>Nova Senha</h2>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Input
                type="password"
                label="Senha Atual"
                value={passwordForm.current_password}
                onChange={event => handleInputPasswordChange('current_password', event)}
                error={passwordError?.current_password}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="password"
                label="Nova Senha"
                value={passwordForm.new_password}
                onChange={event => handleInputPasswordChange('new_password', event)}
                error={passwordError?.new_password}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                type="password"
                label="Confirmar Nova Senha"
                value={passwordForm.new_password2}
                onChange={event => handleInputPasswordChange('new_password2', event)}
                error={passwordError?.new_password2}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Button
        className={styles.saveButton}
        disabled={loading}
        color="secondary"
        label="Salvar"
        size="large"
        onClick={saveUserPassword}
      />
    </>
  );
}

export default MyProfile;
