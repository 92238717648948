// hooks
import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SchedulesRepository } from 'data/schedules';
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';
import { UserHoursRepository } from 'data/usersHour';
import { AuthContext } from 'contexts/auth';

// @fullcalendar
// import { Draggable } from '@fullcalendar/interaction';

export default function useScheduleHanlders(defaultEvents, setModalController) {
  const { clinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);
  const [events, setEvents] = useState(defaultEvents);
  const [professionalWorkingHours, setProfessionalWorkingHours] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  moment.locale('pt-br');

  const loadProfessionalWorkingHours = async () => {
    const response = await UserHoursRepository.list(user.id, clinicId);
    setProfessionalWorkingHours(response?.results ?? []);
  }

  useEffect(() => {
    if (clinicId != null) {
      loadProfessionalWorkingHours();
    }
  }, [clinicId]);

  const statusTable = {
    waiting: '#d3a332',
    attended: '#15ab72',
    canceled: '#ad0303',
    didnotattend: '#77ACD8',
  };

  useEffect(() => {
    if (defaultEvents.length > 0) {
      const newDefaultEvents = defaultEvents.map(event => {
        const hasArrived = event.arrival_time != null && event.departure_time !== null;
        return {
          ...event,
          title: `${event.patient_name ?? 'Paciente'} > ${event.procedure_name ?? 'Procedimento'}`,
          start: hasArrived ? new Date(event.arrival_time) : new Date(event.schedule_date),
          end: hasArrived ? new Date(event.departure_time) : new Date(event.schedule_date_end ?? event.schedule_date),
          color: statusTable[event.status],
        };
      });
      setEvents(newDefaultEvents);
    } else {
      setEvents([]);
    }
  }, [defaultEvents]);

  // transforma os elemtos de fora da agenda em draggable elements
  // useEffect(() => {
  //   let draggableEl = document.getElementById('external-events');
  //   new Draggable(draggableEl, {
  //     itemSelector: '.external-event',
  //   });
  // }, []);

  function onDateClick(arg) {
    let selectedDateTime;
    if (arg.dateStr && arg.dateStr.includes('T')) {
      selectedDateTime = moment(arg.dateStr);
    } else {
      selectedDateTime = moment();
    }
    setModalController({
      open: true,
      event: 'add',
      eventData: {
        schedule_date: selectedDateTime.format('YYYY-MM-DD'),
        hour: selectedDateTime.format('HH:mm'),
        hour_end: selectedDateTime.add(30, 'minutes').format('HH:mm'),
      },
    });
  }

  const onEventClick = async (arg) => {
    setLoading(true);
    const scheduleId = parseInt(arg.event.id);
    const scheduleData = await SchedulesRepository.get(scheduleId);
    setLoading(false);
    setModalController({
      open: true,
      event: 'view',
      eventData: scheduleData,
    });
  }

  // função para reorganizar os eventos ao arrastar e soltar (drag and drop)
  function onDrop(arg) {
    const dropEventData = arg;
    const title = dropEventData.draggedEl.title;
    if (title == null) {
    } else {
      let newEvent = {
        id: String(events.length + 1),
        title: title,
        start: dropEventData ? dropEventData.dateStr : new Date(),
        className: dropEventData.draggedEl.attributes.getNamedItem('data-class')?.value,
      };
      const modifiedEvents = [...events];
      modifiedEvents.push(newEvent);

      setEvents(modifiedEvents);
    }
  }

  function onEventDrop(arg) {
    const modifiedEvents = [...events];
    const idx = modifiedEvents.findIndex(e => String(e['id']) === String(arg.event.id));
    modifiedEvents[idx]['title'] = arg.event.title;
    // modifiedEvents[idx]['className'] = arg.event.classNames;
    modifiedEvents[idx]['start'] = arg.event.start;
    modifiedEvents[idx]['end'] = arg.event.end;
    setEvents(modifiedEvents);
    // setIsEditable(false);
  }


  // faz um post, enviando todos os dados de um agendamento pra api.
  async function addEvent(action, payload) {
    let schedule_date;
    let schedule_date_end;
    if (['add', 'edit', 'reschedule'].includes(action)) {
      schedule_date = payload.schedule_date;
      schedule_date_end = payload.schedule_date_end;
      if (payload.hour && (typeof schedule_date === 'string' || schedule_date instanceof String)) {
        schedule_date = schedule_date.includes('T') ? schedule_date.split('T')[0] : schedule_date;
        if (payload.hour_end) {
          schedule_date_end = `${schedule_date}T${payload.hour_end}:00Z`;
        }
        schedule_date = `${schedule_date}T${payload.hour}:00Z`;
      } else {
        schedule_date = moment(schedule_date).toISOString();
      }
    }

    switch (action) {
      case 'add':
      case 'edit':
        try {
          const scheduleData = {
            clinic: clinicId,
            user: user.id,
            patient: payload.patient,
            schedule_date: schedule_date,
            schedule_date_end: schedule_date_end,
            description: payload.description,
            procedure: payload.procedure,
            file_0: payload.file_0,
            status: 'scheduled',
            repeat_days: payload.repeat_days,
            repeat_total: payload.repeat_total,
          };
          const scheduleResponse = await (
            action === 'add' ? 
              SchedulesRepository.addSchedule(scheduleData) : 
              SchedulesRepository.editSchedule(payload.id, scheduleData)
          );

          const hasArrived = scheduleResponse.arrival_time != null && scheduleResponse.departure_time !== null;
          const oldEvents = action === 'add' ? events : events.filter(e => e.id !== payload.id);
          setEvents([
            ...oldEvents,
            {
              ...scheduleResponse,
              title: `${scheduleResponse.patient_name ?? 'Paciente'} > ${scheduleResponse.procedure_name ?? 'Procedimento'}`,
              start: hasArrived ? new Date(scheduleResponse.arrival_time) : new Date(scheduleResponse.schedule_date),
              end: hasArrived ? new Date(scheduleResponse.departure_time) : new Date(scheduleResponse.schedule_date_end ?? scheduleResponse.schedule_date),
              color: statusTable[scheduleResponse.status],
            }
          ]);

          if (scheduleData.repeat_days && scheduleData.repeat_days.length > 0 && scheduleResponse) {
            window.location.reload();
          }

          return scheduleResponse;
        } catch (error) {
          return { error: 'Erro ao adicionar o agendamento. Tente novamente mais tarde.' }
        }
      case 'addFile':
        try {
          const scheduleResponse = await SchedulesRepository.addScheduleFile(payload.schedule, payload);
          return scheduleResponse;
        } catch (error) {
          console.error(error);
        }
        break;
      case 'reschedule':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            schedule_date: schedule_date,
            schedule_date_end: schedule_date_end,
            cancellation_reason: payload.cancellation_reason,
            cancellation_date: payload.cancellation_date,
            status: 'scheduled',
          });
          const event = events.find(e => e.id === payload.id);
          event.schedule_date = schedule_date;
          event.schedule_date_end = schedule_date_end;
          if (event.arrival_time == null || event.departure_time == null) {
            event.start = moment(schedule_date).add(3, 'hours').toDate();
            event.end = moment(schedule_date_end).add(3, 'hours').toDate();
          }
          event.status = 'scheduled';
          event.color = statusTable['scheduled'];
          setEvents([...events.filter(e => e.id !== payload.id), event]);
          return { error: null };
        } catch (error) {
          return { error: 'Erro ao reagendar. Tente novamente mais tarde.' }
        }
      case 'cancel':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            cancellation_reason: payload.cancellation_reason,
            cancellation_date: payload.cancellation_date,
            status: 'canceled',
          });
          const event = events.find(e => e.id === payload.id);
          event.status = 'canceled';
          event.color = statusTable['canceled'];
          setEvents([...events.filter(e => e.id !== payload.id), event]);
          return { error: null };
        } catch (error) {
          return { error: 'Erro ao cancelar. Tente novamente mais tarde.' }
        }
      case 'didnotattend':
        try {
          await SchedulesRepository.editSchedule(payload.id, { status: 'didnotattend', arrival_time: null });
          const event = events.find(e => e.id === payload.id);
          event.arrival_time = null;
          event.status = 'didnotattend';
          event.color = statusTable['didnotattend'];
          setEvents([...events.filter(e => e.id !== payload.id), event]);
          return { error: null };
        } catch (error) {
          return { error: 'Erro ao alterar. Tente novamente mais tarde.' }
        }
      case 'waiting':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            status: 'waiting',
            arrival_time: moment().format('YYYY-MM-DDTHH:mm:ss')
          });
          const event = events.find(e => e.id === payload.id);
          event.status = 'waiting';
          event.color = statusTable['waiting'];
          setEvents([...events.filter(e => e.id !== payload.id), event]);
          return { error: null };
        } catch (error) {
          return { error: 'Erro ao alterar. Tente novamente mais tarde.' }
        }
      default:
        break;
    }
    return false;
  }

  return { events, professionalWorkingHours, onDateClick, onEventClick, onDrop, onEventDrop, addEvent };
}
