
import '@fullcalendar/react';
import './Calendar.module.scss'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import momentPlugin from '@fullcalendar/moment';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

const Calendar = ({
  events,
  onDateClick,
  onEventClick,
  onDrop,
  onEventDrop,
  customButtons,
  customHeaderToolbar,
  customFooterToolbar,
  slotMinTime,
  slotMaxTime,
  resources,
  buttonText = {},
}) => {
  slotMinTime = slotMinTime ?? '00:00:00';
  slotMaxTime = slotMaxTime ?? '23:59:00';
  const initialHour = parseInt(slotMinTime.split(':')[0]);
  const finalHour = parseInt(slotMaxTime.split(':')[0]);
  let height = 154 + (finalHour - initialHour) * 54;
  if (height < 570) {
    height = 570;
  } else if (height > 800) {
    height = 800;
  }
  const plugins = [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin];
  if (resources != null) {
    plugins.push(resourceTimeGridPlugin);
  }
  return (
    <FullCalendar
      allDaySlot={false}
      locale={ptBrLocale}
      initialView={resources ? "resourceTimeGridDay" : "timeGridWeek"}
      schedulerLicenseKey='0978726622-fcs-1571773945'
      slotMinTime={slotMinTime}
      slotMaxTime={slotMaxTime}
      plugins={plugins}
      views={{
        month: {
          titleFormat: 'MMMM YY',
        },
        week: {
          titleFormat: 'MMM YY',
        },
        day: {
          titleFormat: 'DD MMM YYYY',
        },
      }}
      // validRange={{
      //   start: '2023-12-10',
      //   end: '2023-12-20',
      // }}
      handleWindowResize={true}
      height={height}
      themeSystem="standard"
      buttonText={{
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista',
        prev: '<<<',
        next: '>>>',
        ...buttonText
      }}
      eventDisplay="block"
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
      }}
      displayEventTime={true}
      displayEventEnd={true}
      resources={resources}
      customButtons={customButtons}
      headerToolbar={
        customHeaderToolbar
          ? customHeaderToolbar
          : {
            left: 'prev,today,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }
      }
      footerToolbar={customFooterToolbar ?? {}}
      editable={true}
      selectable={true}
      droppable={true}
      events={events}
      // events='https://fullcalendar.io/api/demo-feeds/events.json?with-resources=4&single-day'
      dateClick={onDateClick}
      eventClick={onEventClick}
      drop={onDrop}
      eventDrop={onEventDrop}
    />
  );
};

export { Calendar };
