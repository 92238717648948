import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import Home from 'pages/shared/Home/Home';
import MyProfile from 'pages/shared/MyProfile/MyProfile';

export const routes = [
  {
    key: '/home',
    path: '/home',
    element: (
      <Authenticated>
        <DefaultLayout tab="/home">
          <Home />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/meu-perfil',
    path: '/meu-perfil',
    element: (
      <Authenticated>
        <DefaultLayout tab="/home">
          <MyProfile />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
