// scss
import styles from './EventModal.module.scss';

// components
import Modal from 'components/Modal/Modal';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Button as MuiButton } from '@mui/material';
import TextArea from 'components/TextArea/TextArea';
import Autocomplete from 'components/Autocomplete/Autocomplete';

// other
import Enums from 'transformers/enums';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useState, useContext } from 'react';
import { MdFileUpload } from 'react-icons/md';
import { getWeekdayFromStrDate, handleSelectOneFile } from 'shared/utility';
import { LoaderContext } from 'contexts/loader';
import Checkbox from 'components/Checkbox/Checkbox';
import { UserRepository } from 'data/users';
import { ClinicIdContext } from 'contexts/clinicId';
import { UserHoursRepository } from 'data/usersHour';
import { FaRegFile } from 'react-icons/fa';

moment.locale('pt-br');

export default function EventModal({ props }) {
  const { modalController, setModalController, onModalClose, addEvent, patients, procedures, professionals: _professionals } = props;
  const { clinicId } = useContext(ClinicIdContext);
  const [error, _setError] = useState('');
  const [professionals, setProfessionals] = useState(_professionals);
  const [professionalWorkingHours, setProfessionalWorkingHours] = useState([]);
  const { setLoading } = useContext(LoaderContext);

  
  const loadProfessionals = async selectedProcedure => {
    const filters = { clinic: clinicId };
    if (selectedProcedure.profile === 'doctor') {
      filters.user_type = '1';
    }
    if (selectedProcedure.profile === 'other' && selectedProcedure.service_type != null) {
      filters.user_type = '7';
      filters.service_type = selectedProcedure.service_type;
    }
    const userResponse = await UserRepository.list(filters);
    const newProfessionals = userResponse?.results ?? [];
    if (modalController.eventData.user && !newProfessionals.find(p => p.id === modalController.eventData.user)) {
      handleChange('user', null);
    }
    setProfessionals(newProfessionals);
  }

  const loadProfessionalWorkingHours = async professional => {
    const response = await UserHoursRepository.list(professional, clinicId);
    setProfessionalWorkingHours(response?.results ?? []);
  }

  const setError = newError => {
    _setError(newError);
    setTimeout(() => _setError(''), 4000);
  }

  function handleChange(prop, value) {
    setModalController(prevModalState => ({
      ...prevModalState,
      eventData: { ...prevModalState.eventData, [prop]: value },
    }));
  }

  const validateHourForProfessional = () => {
    if(modalController?.eventData?.user == null) {
      setError('Selecione um profissional');
      return false;
    }

    if (professionalWorkingHours.length === 0) {
      //ignora pois não tem definição, fica a critério do usuário controlar a agenda
      return true;
    }

    const selectedDate = modalController?.eventData?.schedule_date;
    const weekday = selectedDate ? getWeekdayFromStrDate(new Date(selectedDate)) : '';
    const professionalDayHours = professionalWorkingHours.filter(hours => hours.day === weekday);
    if (professionalDayHours.length === 0) {
      setError('O profissional não trabalha no consultório neste dia');
      return false;
    }

    const selectedHour = modalController?.eventData?.hour;
    if (selectedHour) {
      let hourSplit = selectedHour.toString().split(':');
      if (hourSplit.length > 0) {
        let hour = parseInt(hourSplit[0]);
        const selectedHourInMinutes = parseInt(hourSplit[1]) + hour * 60;

        for (const hours of professionalDayHours) {
          hourSplit = hours.start_time.split(':');
          hour = parseInt(hourSplit[0]);
          const startWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          
          hourSplit = hours.end_time.split(':');
          hour = parseInt(hourSplit[0]);
          const endWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          if (selectedHourInMinutes >= startWorkingInMinutes && selectedHourInMinutes <= endWorkingInMinutes) {
            return true;
          }        
        }
      }
    }

    setError('O profissional não está disponível neste horário');
    return false;
  }

  const procedureOptions = procedures.map(item => ({ label: item.name, value: item.id.toString() }));
  const patientsOptions = patients.map(item => ({ label: `${item.name} - CPF: ${item.cpf.slice(0, 3)}.***.***-**`, value: item.id })); 
  const professionalsOptions = professionals.map(item => ({ label: `${item.first_name} ${item.last_name}`, value: item.id })); 
  const _selectedProcedures = (modalController?.eventData?.procedure ?? '').toString().split(',');
  const selectedProcedures = [...new Set(_selectedProcedures)]; //remove duplicates
  const repeatCheckedIsSelected = modalController?.eventData?.repeat_total != null;
  if (['add', 'edit'].includes(modalController.event)) {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>{modalController.event === 'add' ? 'Adicionar Agendamento' : 'Editar Agendamento'}</h1>
        </div>
        <div className={styles.body}>
          <div className={styles.selected_professional}>
            <Autocomplete
              label="Profissional"
              className={styles.autocomplete}
              value={professionalsOptions.find(item => item.value === modalController.eventData.user)}
              options={professionalsOptions}
              onChange={(_, item) => {
                if (item != null) {
                  loadProfessionalWorkingHours(item.value);
                  handleChange('user', item.value);
                }
              }}
            />
            <Autocomplete
              label="Paciente"
              className={styles.autocomplete}
              value={patientsOptions.find(item => item.value === modalController.eventData.patient)}
              options={patientsOptions}
              onChange={(_, item) => {
                if (item != null) {
                  handleChange('patient', item.value);
                }
              }}
            />
          </div>
          <div className={styles.date_container}>
            <div className={styles.date_input}>
              <span>Data Agendamento</span>
              <Input
                type="date"
                value={modalController.eventData.schedule_date}
                onChange={event => handleChange('schedule_date', event.target.value)}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Início)</span>
              <Input
                type="time"
                value={modalController.eventData.hour}
                onChange={event => {
                  handleChange('hour', event.target.value);
                }}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Fim)</span>
              <Input
                type="time"
                value={modalController.eventData.hour_end}
                onChange={event => {
                  handleChange('hour_end', event.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.repeat} style={{ padding: `${repeatCheckedIsSelected ? '0' : '4.5'}px 0` }}>
            <Checkbox
              label="É recorrente"
              single
              selected={repeatCheckedIsSelected}
              onClick={() => {
                if (repeatCheckedIsSelected) {
                  handleChange('repeat_days', null);
                  handleChange('repeat_total', null);
                } else {
                  handleChange('repeat_days', '7');
                  handleChange('repeat_total', '4');
                }
              }}
            />  
            {repeatCheckedIsSelected ? (
              <span>
                , repete a cada 
                <div className={styles.repeatInput}>
                  <Input
                    inputProps={{ maxLength: 2 }}
                    value={modalController.eventData.repeat_days}
                    onChange={event => handleChange('repeat_days', event.target.value.replace(/\D/g, ''))}
                  /> 
                </div>
                dias sendo um total de 
                <div className={styles.repeatInput}>
                  <Input
                    inputProps={{ maxLength: 2 }}
                    value={modalController.eventData.repeat_total}
                    onChange={event => handleChange('repeat_total', event.target.value.replace(/\D/g, ''))}
                  /> 
                </div>
                ocorrências.
              </span> 
            ) : (
              <span>?</span>
            )}         
          </div>
          <Autocomplete
            label="Procedimento"
            value={selectedProcedures.length > 0 ? procedureOptions.find(item => item.value === selectedProcedures[0]) : null}
            options={procedureOptions}
            onChange={(_, item) => {
              if (item != null) {
                loadProfessionals(procedures.find(p => p.id.toString() === item.value ));
                const newProcedure = [ ...selectedProcedures ];
                if (newProcedure.length > 0) {
                  newProcedure[0] = item.value;
                } else {
                  newProcedure.push(item.value);
                }
                setModalController(prevModalState => ({
                  ...prevModalState,
                  eventData: { 
                    ...prevModalState.eventData, 
                    procedure: newProcedure.join(','),
                  },
                }));
              } else {
                handleChange('procedure', null);
              }
            }}
          />
          {modalController.eventData.procedure != null && selectedProcedures.map((_, index) => {
            const hasSelected = index+1 < selectedProcedures.length;
            const procedure_id = hasSelected ? selectedProcedures[index+1] : null;
            const filteredOptions = procedureOptions.filter(item => item.value === procedure_id || !selectedProcedures.includes(item.value));
            if (filteredOptions.length === 0) {
              return '';
            }
            return (
              <Autocomplete
                label="+ Procedimento (opcional)"
                value={procedureOptions.find(item => item.value === procedure_id)}
                options={filteredOptions}
                onChange={(_, item) => {
                  if (item != null) {
                    const newProcedure = [ ...selectedProcedures ];
                    if (hasSelected) {
                      newProcedure[index+1] = item.value;
                    } else {
                      newProcedure.push(item.value);
                    }
                    handleChange('procedure', newProcedure.join(','));
                  } else {
                    handleChange('procedure', selectedProcedures.slice(0, index+1));
                  }
                }}
              />
            )
          })}
          <TextArea
            minRows={3}
            placeholder="Observações"
            value={modalController.eventData.description}
            onChange={event => handleChange('description', event.target.value)}
          />
        </div>
        <div className={styles.footer}>
          {modalController.event === 'edit' && (
            <Button
              label="Voltar"
              onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
            />
          )}
          <Button
            label={modalController.event === 'edit' ? 'Salvar' : 'Agendar'}
            className={styles.button_add}
            onClick={async () => {
              if (validateHourForProfessional()) {
                setLoading(true);
                await addEvent(modalController.event, modalController.eventData, setModalController);
                setLoading(false);
              }
            }}
          />
        </div>
        {error.length > 0 && <div className={styles.footer_error}>{error}</div>}
      </Modal>
    );
  }

  if (modalController.event === 'view') {
    const protocolName = modalController.eventData?.protocol_name ?? '-';
    const procedureNames = (modalController.eventData?.procedure_name ?? '-').split(', ');
    const scheduleObs = modalController.eventData?.description ?? (modalController.eventData?.name ?? '-');
    const files = modalController.eventData?.files ?? [];
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Visualizar Agendamento</h1>
        </div>
        <div className={styles.body}>
          <div className={`${styles.table} ${styles.tableSingleRowBordered}`}>
            <div className={styles.left} style={{ alignSelf: 'center' }}>
              <span>Procedimento(s)</span>
            </div>
            <div className={styles.right} style={{ paddingRight: '6px' }}>
              {procedureNames.map((pName, index) => <div key={`${pName}-${index}`}>{protocolName}{' > '}{pName}<br/></div>)}
            </div>
          </div>
          <div className={`${styles.table} ${styles.tableBordered}`}>
            <div className={styles.left}>
              <span>Status</span>
              <span>Profissional</span>
              <span>Data e Hora</span>
              <span>Paciente</span>
              <span>Obs. do Paciente</span>
              <span>Obs. do Agendamento</span>
              {files.map((f, idx) => (
                <span key={`name${idx}`}><FaRegFile />{f.filename}</span>
              ))}
            </div>
            <div className={styles.right}>
              <span>{Enums.statusSchedule[modalController.eventData?.status] ?? 'Não definido'}</span>
              <span>{modalController.eventData?.professional_name ?? '-'}</span>
              <span>{moment(modalController.eventData?.schedule_date).format('lll')}</span>
              <span>{modalController.eventData?.patient_name ?? '-'}</span>
              <span title={modalController.eventData?.patient_observation}>{modalController.eventData?.patient_observation ?? '-'}</span>
              <span title={scheduleObs}>{scheduleObs}</span>
              {files.map((f, idx) => (
                  <span key={`file${idx}`}>
                    {f.id ? (
                      <a href={f.file} target='_blank' rel="noreferrer">Visualizar arquivo</a>
                    ) : (
                      <a href="##" onClick={() => {
                        const w = window.open("");
                        if (f.file.includes('data:application/pdf')) {
                          w.document.write(`<iframe width='100%' height='100%' src='${f.file}'></iframe>`);
                        } else {
                          var image = new Image();
                          image.src = f.file;
                          w.document.write(image.outerHTML);
                        }
                      }}>Visualizar arquivo</a>
                    )}
                  </span>
                ))}
            </div>
          </div>
          <div className={`${styles.table} ${styles.tableSingleRowBordered}`}>
            <div className={styles.left} style={{ alignSelf: 'center' }}>
              <span>Novo Arquivo</span>
            </div>
            <div className={styles.right}>
              <span>
                <MuiButton component="label" style={{ paddingRight: 0 }}>
                  {modalController.eventData?.filename == null ? (
                    <>
                      <MdFileUpload />
                      Anexar Arquivo
                    </>
                  ) : modalController.eventData.filename}
                  <input
                    hidden
                    onClick={() => {
                      handleSelectOneFile('.jpg, .jpeg, .png, .pdf', (invoice_file, invoice_file64) => {
                        setLoading(true);
                        addEvent(
                          'addFile', 
                          {
                            schedule: modalController.eventData.id,
                            file: invoice_file,
                            filename: invoice_file.name,
                          }, 
                          data => {
                            if (data.error == null) {
                              setModalController(prevModalState => ({
                                ...prevModalState,
                                eventData: {
                                  ...prevModalState.eventData,
                                  files: [
                                    ...prevModalState.eventData.files,
                                    data,
                                  ],
                                },
                              }))
                            }
                            setLoading(false);
                          },
                        );
                      });
                    }}
                  />
                </MuiButton>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {modalController?.eventData?.status !== 'attended' && modalController?.eventData?.status !== 'canceled' && (
            <>
              {modalController.eventData?.status !== 'didnotattend' && (
                <Button
                  label="Paciente Não Compareceu"
                  className={styles.button_didnotattend}
                  onClick={async () => {
                    setLoading(true);
                    const success = await addEvent('didnotattend', modalController.eventData, setModalController);
                    setLoading(false);
                    if (success) {
                      onModalClose();
                    }
                  }}
                />
              )}  
              <Button
                label="Cancelar"
                className={styles.button_cancel}
                onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'cancel' }))}
              />
              <Button
                label="Reagendar"
                className={styles.button_reschedule}
                onClick={() => {
                  const scheduleDate = moment(modalController.eventData.schedule_date);
                  const scheduleDateEnd = moment(modalController.eventData.schedule_date_end);
                  setModalController(prevModalState => ({ 
                    ...prevModalState, 
                    event: 'reschedule',
                    eventData: {
                      ...modalController.eventData,
                      schedule_date: scheduleDate.format('YYYY-MM-DD'),
                      hour: scheduleDate.format('HH:mm'),
                      hour_end: scheduleDateEnd ? scheduleDateEnd.format('HH:mm') : null,
                    }
                  }));
                }}
              />
              <Button
                label="Editar"
                className={styles.button_edit}
                onClick={() => {
                  const scheduleDate = moment(modalController.eventData.schedule_date);
                  const scheduleDateEnd = moment(modalController.eventData.schedule_date_end);
                  setModalController({ 
                    ...modalController, 
                    event: 'edit', 
                    eventData: {
                      ...modalController.eventData,
                      schedule_date: scheduleDate.format('YYYY-MM-DD'),
                      hour: scheduleDate.format('HH:mm'),
                      hour_end: scheduleDateEnd ? scheduleDateEnd.format('HH:mm') : null,
                    }
                  });
                }}
              />
              {['scheduled', 'didnotattend'].includes(modalController.eventData?.status) && (
                <Button
                  label="Paciente Chegou"
                  className={styles.button_arrived}
                  onClick={async () => {
                    setLoading(true);
                    const success = await addEvent('waiting', modalController.eventData, setModalController);
                    setLoading(false);
                    if (success) {
                      onModalClose();
                    }
                  }}
                />
              )}              
            </>
          )}
        </div>
      </Modal>
    );
  }

  if (modalController.event === 'success') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Horário salvo com sucesso!</h1>
        </div>
        <div className={styles.footer}>
          <Button label="Fechar" className={styles.button_close} onClick={() => onModalClose()} />
        </div>
      </Modal>
    );
  }

  if (modalController.event === 'error') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Erro!</h1>
        </div>
        <div className={styles.body}>
          <p className={styles.modal_error}>{modalController.message}</p>
        </div>
        <div className={styles.footer}>
          <Button label="Fechar" className={styles.button_close} onClick={() => onModalClose()} />
        </div>
      </Modal>
    );
  }

  if (modalController.event === 'cancel') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Motivo do cancelamento</h1>
        </div>
        <div className={styles.body}>
          <TextArea
            minRows={3}
            placeholder="Motivo"
            value={modalController.eventData.cancellation_reason}
            onChange={event => {
              handleChange('cancellation_reason', event.target.value);
              handleChange('cancellation_date', moment().format('YYYY-MM-DD'));
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            label="Voltar"
            onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
          />
          <Button
            label="Confirmar"
            className={styles.button_arrived}
            onClick={async () => {
              setLoading(true);
              const success = await addEvent('cancel', modalController.eventData, setModalController);
              setLoading(false);
              if (success) {
                onModalClose();
              }
            }}
          />
        </div>
      </Modal>
    );
  }

  if (modalController.event === 'reschedule') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onModalClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Reagendamento</h1>
        </div>
        <div className={styles.body}>
          <div className={styles.date_container}>
            <div className={styles.date_input}>
              <span>Nova data</span>
              <Input
                type="date"
                value={modalController.eventData.schedule_date}
                onChange={event => handleChange('schedule_date', event.target.value)}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Início)</span>
              <Input
                type="time"
                value={modalController.eventData.hour}
                onChange={event => {
                  handleChange('hour', event.target.value);
                }}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Fim)</span>
              <Input
                type="time"
                value={modalController.eventData.hour_end}
                onChange={event => {
                  handleChange('hour_end', event.target.value);
                }}
              />
            </div>
          </div>
          <TextArea
            minRows={3}
            placeholder="Motivo"
            value={modalController.eventData.cancellation_reason}
            onChange={event => {
              handleChange('cancellation_reason', event.target.value);
              handleChange('cancellation_date', moment().format('YYYY-MM-DD'));
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            label="Voltar"
            onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
          />
          <Button
            label="Confirmar"
            className={styles.button_arrived}
            onClick={async () => {
              if (validateHourForProfessional(modalController?.eventData?.hour)) {
                setLoading(true);
                const success = await addEvent('reschedule', modalController.eventData, setModalController);
                setLoading(false);
                if (success) {
                  onModalClose();
                }
              }
            }}
          />
        </div>
        {error.length > 0 && <div className={styles.footer_error}>{error}</div>}
      </Modal>
    );
  }
}
