import * as yup from 'yup';

const userDataValidation = yup.object({
  first_name: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]{3,}$/, 'Nome deve conter 3 ou mais letras e não pode conter números.')
    .required('Nome é um campo obrigatório'),
  last_name: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]{3,}$/, 'Sobrenome deve conter 3 ou mais letras e não pode conter números.')
    .required('Sobrenome é um campo obrigatório'),
  phone_number: yup.string(),
  photo: yup.mixed().nullable(),
});

const userPasswordValidation = yup.object({
  current_password: yup
    .string()
    .required('Defina sua senha atual'),
  new_password: yup
    .string()
    .min(8, 'Deve conter ao menos 8 caracteres')
    .required('Defina sua nova senha'),
  new_password2: yup
    .string()
    .min(8, 'Deve conter ao menos 8 caracteres')
    .required('Confirme sua nova senha'),
});

export const validateUserData = async data => {
  return await userDataValidation.validate(data, { abortEarly: false });
};

export const validateUserPassword = async data => {
  return await userPasswordValidation.validate(data, { abortEarly: false });
};
