// scss
import styles from './ConciergeHome.module.scss';

// components
import Container from 'components/Container/Container';
import ConciergeSchedule from './Schedule/Schedule';

function ConciergeHome() {
  return (
    <Container className={styles.container}>
      <ConciergeSchedule />
    </Container>
  );
}
export default ConciergeHome;
