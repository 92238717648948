import Endpoints from '../config/endpoints';
import http, { axiosBaseURL } from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';
import axios from 'axios';

export const UserRepository = {
  userBaseUrl: Endpoints.users,

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters(filters);
      const { data } = await http.get(`${this.userBaseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  update: async function (id, data) {
    try {
      const response = await http.patch(`${this.userBaseUrl}/${id}`, data);
      return response.data;
    } catch (err) {
      return parseError(err);
    }
  },
  getUser: async function (id) {
    try {
      const { data } = await http.get(`${this.userBaseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteUser: async function (id) {
    try {
      const { data } = await http.delete(`${this.userBaseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  listSpecialties: async function (id) {
    try {
      const { data } = await http.get(`${this.userBaseUrl}/${id}/specialties`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updatePhoto: async function (id, photo) {
    try {
      const blob = await fetch(photo).then(res => res.blob());

      const formData = new FormData();
      formData.append('photo', blob, 'photo.png');
      formData.append('photo', photo);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return await http.patch(`${this.userBaseUrl}/${id}`, formData, config);
    } catch (err) {
      return parseError(err);
    }
  },
  addUserData: async function (body) {
    try {
      const photo = body.photo || body.photo64;
      delete body.photo;
      delete body.photo64;
      const { data } = await http.post(`${this.userBaseUrl}`, body);
      if (photo) {
        await UserRepository.updatePhoto(data.id, photo);
      }
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateUserPassword: async function (userId, userEmail, body) {
    try {
      await axios.post(`${axiosBaseURL}${Endpoints.auth}`, {
        email: userEmail,
        password: body.current_password,
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        return { error: { current_password: 'Senha inválida' } };
      } else {
        return parseError(err);
      }      
    }
    try {
      const { data } = await http.patch(`${this.userBaseUrl}/${userId}`, { password: body.new_password });
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateUserData: async function (id, body) {
    try {
      const photo = body.photo;
      delete body.photo;
      delete body.photo64;
      const { data } = await http.patch(`${this.userBaseUrl}/${id}`, body);
      if (photo && !(typeof photo === 'string' || photo instanceof String)) {
        await UserRepository.updatePhoto(data.id, photo);
      }
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateServiceType: async function (userId, serivceTypeId, body) {
    try {
      const { data } = await http.patch(`${this.userBaseUrl}/${userId}/service-types/${serivceTypeId}`, body);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateDoctorCRM: async function (userId, doctorId, body) {
    try {
      const { data } = await http.patch(`${this.userBaseUrl}/${userId}/doctor/${doctorId}`, body);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateDoctorSpecialties: async function (id, body) {
    try {
      const { data } = await http.post(`${this.userBaseUrl}/${id}/specialties`, body);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
