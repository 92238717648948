import { useState, useContext, useEffect } from 'react';
import styles from './EventModal.module.scss';
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';

// components
import Modal from 'components/Modal/Modal';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import TextArea from 'components/TextArea/TextArea';
import { Button as MuiButton } from '@mui/material';
import { MdFileUpload } from 'react-icons/md';
import { handleSelectOneFile } from 'shared/utility';
import { useToast } from 'hooks/useToast'

// other
import Enums from 'transformers/enums';
import moment from 'moment';
import 'moment/locale/pt-br';
import { PatientsRepository } from 'data/patients';
import { ProceduresConciergeRepository } from 'data/procedures-concierge';
import Checkbox from 'components/Checkbox/Checkbox';
import { FaRegFile } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function EventModal({ props }) {
  const toast = useToast();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  const [error, _setError] = useState('');
  const { modalController, setModalController, onClose, addEvent } = props;
  const [procedures, setProcedures] = useState([]);
  const [patients, setPatients] = useState([]);

  moment.locale('pt-br');
  
  async function fetchData() {
    setLoading(true);

    const proceduresResponse = await ProceduresConciergeRepository.list({ clinic: clinicId, limit: 9999 });
    if (proceduresResponse && proceduresResponse.results) {
      setProcedures(proceduresResponse.results);
    };

    const patientsResponse = await PatientsRepository.list({ limit: 9999 });
    if (patientsResponse && patientsResponse.results) {
      setPatients(patientsResponse.results);
    }
    
    setLoading(false);
  }

  useEffect(() => {
    if (clinicId != null && patients.length === 0 && ['add', 'edit'].includes(modalController.event)) {
      fetchData();
    }
  }, [clinicId, modalController.event]);
  
  const setError = newError => {
    _setError(newError);
    setTimeout(() => _setError(''), 4000);
  }

  function handleChange(prop, value) {
    setModalController(prevModalState => ({
      ...prevModalState,
      eventData: { ...prevModalState.eventData, [prop]: value },
    }));
  }
  
  let minHour = 0;
  let maxHour = 23;
  const ignoreProfessionalDayHours = modalController?.eventData?.professionalDayHours == null; //ignora pois não tem definição
  const professionalDayHours = ignoreProfessionalDayHours ? [] : modalController.eventData.professionalDayHours;
  if (professionalDayHours.length > 0) {
    minHour = 23;
    maxHour = 0;
    professionalDayHours.forEach(hours => {
      try {
        let hourSplit = hours.start_time.split(':');
        let hour = parseInt(hourSplit[0]);
        if (hour < minHour) {
          minHour = hour;
        }
        
        hourSplit = hours.end_time.split(':');
        hour = parseInt(hourSplit[0]);
        if (hour > maxHour) {
          maxHour = hour;
        }
      } catch {}
    });
  }

  const validateHourForProfessional = selectedHour => {
    if (selectedHour) {
      if (ignoreProfessionalDayHours) {
        return true;
      }
      let hourSplit = selectedHour.toString().split(':');
      if (hourSplit.length > 0) {
        let hour = parseInt(hourSplit[0]);
        const selectedHourInMinutes = parseInt(hourSplit[1]) + hour * 60;

        for (const hours of professionalDayHours) {
          hourSplit = hours.start_time.split(':');
          hour = parseInt(hourSplit[0]);
          const startWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          
          hourSplit = hours.end_time.split(':');
          hour = parseInt(hourSplit[0]);
          const endWorkingInMinutes = parseInt(hourSplit[1]) + hour * 60;
          if (selectedHourInMinutes >= startWorkingInMinutes && selectedHourInMinutes <= endWorkingInMinutes) {
            return true;
          }        
        }
      }
    }
    return false;
  }

  if (['add', 'edit'].includes(modalController.event)) {
    const procedureOptions = procedures.map(item => ({ label: item.name, value: item.id.toString() }));
    const _selectedProcedures = (modalController?.eventData?.procedure ?? '').toString().split(',');
    const selectedProcedures = [...new Set(_selectedProcedures)]; //remove duplicates
    const repeatCheckedIsSelected = modalController?.eventData?.repeat_total != null;
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>{modalController.event === 'add' ? 'Adicionar Agendamento' : 'Editar Agendamento'}</h1>
          <h6>Dia {moment(modalController.eventData.schedule_date).format('LL')} </h6>
        </div>
        <div className={styles.body}>
          <Autocomplete
            label="Paciente"
            className={styles.patientsAutocomplete}
            value={patients
              .map(item => ({ label: `${item.name} - CPF: ${item.cpf.slice(0, 3)}.***.***-**`, value: item.id }))
              .find(item => item.value === modalController.eventData.patient)}
            options={patients.map(item => ({ label: `${item.name} - CPF: ${item.cpf.slice(0, 3)}.***.***-**`, value: item.id }))}
            onChange={(_, item) => {
              if (item != null) {
                handleChange('patient', item.value);
              }
            }}
          />
          <div className={styles.date_container}>
            <div className={styles.date_input}>
              <span>Data Agendamento</span>
              <Input
                type="date"
                value={modalController.eventData.schedule_date}
                onChange={event => handleChange('schedule_date', event.target.value)}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Início)</span>
              <Input
                type="time"
                value={modalController.eventData.hour}
                onChange={event => {
                  handleChange('hour', event.target.value);
                }}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Fim)</span>
              <Input
                type="time"
                value={modalController.eventData.hour_end}
                onChange={event => {
                  handleChange('hour_end', event.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.repeat} style={{ padding: `${repeatCheckedIsSelected ? '0' : '4.5'}px 0` }}>
            <Checkbox
              label="É recorrente"
              single
              selected={repeatCheckedIsSelected}
              onClick={() => {
                if (repeatCheckedIsSelected) {
                  handleChange('repeat_days', null);
                  handleChange('repeat_total', null);
                } else {
                  handleChange('repeat_days', '7');
                  handleChange('repeat_total', '4');
                }
              }}
            />  
            {repeatCheckedIsSelected ? (
              <span>
                , repete a cada 
                <div className={styles.repeatInput}>
                  <Input
                    inputProps={{ maxLength: 2 }}
                    value={modalController.eventData.repeat_days}
                    onChange={event => handleChange('repeat_days', event.target.value.replace(/\D/g, ''))}
                  /> 
                </div>
                dias sendo um total de 
                <div className={styles.repeatInput}>
                  <Input
                    inputProps={{ maxLength: 2 }}
                    value={modalController.eventData.repeat_total}
                    onChange={event => handleChange('repeat_total', event.target.value.replace(/\D/g, ''))}
                  /> 
                </div>
                ocorrências.
              </span> 
            ) : (
              <span>?</span>
            )}         
          </div>
          <Autocomplete
            label="Procedimento"
            value={selectedProcedures.length > 0 ? procedureOptions.find(item => item.value === selectedProcedures[0]) : null}
            options={procedureOptions}
            onChange={(_, item) => {
              if (item != null) {
                const newProcedure = [ ...selectedProcedures ];
                if (newProcedure.length > 0) {
                  newProcedure[0] = item.value;
                } else {
                  newProcedure.push(item.value);
                }
                handleChange('procedure', newProcedure.join(','));
              } else {
                handleChange('procedure', null);
              }
            }}
          />
          {modalController.eventData.procedure != null && selectedProcedures.map((_, index) => {
            const hasSelected = index+1 < selectedProcedures.length;
            const procedure_id = hasSelected ? selectedProcedures[index+1] : null;
            const filteredOptions = procedureOptions.filter(item => item.value === procedure_id || !selectedProcedures.includes(item.value));
            if (filteredOptions.length === 0) {
              return '';
            }
            return (
              <div>
                <Autocomplete
                  label="+ Procedimento (opcional)"
                  value={procedureOptions.find(item => item.value === procedure_id)}
                  options={filteredOptions}
                  onChange={(_, item) => {
                    if (item != null) {
                      const newProcedure = [ ...selectedProcedures ];
                      if (hasSelected) {
                        newProcedure[index+1] = item.value;
                      } else {
                        newProcedure.push(item.value);
                      }
                      handleChange('procedure', newProcedure.join(','));
                    } else {
                      handleChange('procedure', selectedProcedures.slice(0, index+1));
                    }
                  }}
                />
              </div>
            )
          })}
          <TextArea
            minRows={3}
            placeholder="Observações"
            value={modalController.eventData.description}
            onChange={event => handleChange('description', event.target.value)}
          />
        </div>
        <div className={styles.footer}>
          {modalController.event === 'edit' && (
            <Button
              label="Voltar"
              onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
            />
          )}
          <Button
            label={modalController.event === 'edit' ? 'Salvar' : 'Agendar'}
            className={styles.button_add}
            onClick={async () => {
              if (validateHourForProfessional(modalController?.eventData?.hour)) {
                setLoading(true);
                const response = await addEvent(modalController.event, modalController.eventData);
                setLoading(false);
                const errorMsg = response ? response.error : 'Erro ao salvar. Tente novamente mais tarde.';
                if (errorMsg) {
                  setError(errorMsg);
                } else {
                  toast.success('Salvo com sucesso!');
                  onClose();
                }
              } else {
                setError('O profissional não está disponível neste horário');
              }
            }}
          />
        </div>
        {error.length > 0 && <div className={styles.footer_error}>{error}</div>}
      </Modal>
    );
  }
  

  if (modalController.event === 'cancel') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Motivo do cancelamento</h1>
        </div>
        <div className={styles.body}>
          <TextArea
            minRows={3}
            placeholder="Motivo"
            value={modalController.eventData.cancellation_reason}
            onChange={event => {
              handleChange('cancellation_reason', event.target.value);
              handleChange('cancellation_date', moment().format('YYYY-MM-DD'));
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            label="Voltar"
            onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
          />
          <Button
            label="Confirmar"
            className={styles.button_arrived}
            onClick={async () => {
              setLoading(true);
              const response = await addEvent('cancel', modalController.eventData, setModalController);
              setLoading(false);
              if (response.error) {
                setError(response.error);
              } else {
                toast.success('Cancelado com sucesso!');
                onClose();
              }
            }}
          />
        </div>
      </Modal>
    );
  }

  if (modalController.event === 'reschedule') {
    return (
      <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onClose()}>
        <div className={styles.header}>
          <h1 className={styles.title}>Reagendamento</h1>
        </div>
        <div className={styles.body}>
          <div className={styles.date_container}>
            <div className={styles.date_input}>
              <span>Data Agendamento</span>
              <Input
                type="date"
                value={modalController.eventData.schedule_date}
                onChange={event => handleChange('schedule_date', event.target.value)}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Início)</span>
              <Input
                type="time"
                value={modalController.eventData.hour}
                onChange={event => {
                  handleChange('hour', event.target.value);
                }}
              />
            </div>
            <div className={styles.date_input}>
              <span>Horário (Fim)</span>
              <Input
                type="time"
                value={modalController.eventData.hour_end}
                onChange={event => {
                  handleChange('hour_end', event.target.value);
                }}
              />
            </div>
          </div>
          <TextArea
            minRows={3}
            placeholder="Motivo"
            value={modalController.eventData.cancellation_reason}
            onChange={event => {
              handleChange('cancellation_reason', event.target.value);
              handleChange('cancellation_date', moment().format('YYYY-MM-DD'));
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            label="Voltar"
            onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'view' }))}
          />
          <Button
            label="Confirmar"
            className={styles.button_arrived}
            onClick={async () => {
              if (validateHourForProfessional(modalController?.eventData?.hour)) {
                setLoading(true);
                const response = await addEvent('reschedule', modalController.eventData, setModalController);
                setLoading(false);
                if (response.error) {
                  setError(response.error);
                } else {
                  toast.success('Reagendado com sucesso!');
                  onClose();
                }
              } else {
                setError('O profissional não está disponível neste horário');
              }
            }}
          />
        </div>
        {error.length > 0 && <div className={styles.footer_error}>{error}</div>}
      </Modal>
    );
  }

  const protocolName = modalController.eventData?.protocol_name ?? '-';
  const procedureNames = (modalController.eventData?.procedure_name ?? '-').split(', ');
  const files = modalController.eventData?.files ?? [];
  const scheduleObs = modalController.eventData?.description ?? (modalController.eventData?.name ?? '-');
  return (
    <Modal className={styles.container} isOpen={modalController.open} setModalOpen={() => onClose()}>
      <div className={styles.header}>
        <h1 className={styles.title}>Visualizar Agendamento</h1>
      </div>
      <div className={styles.body}>
        <div className={`${styles.table} ${styles.tableSingleRowBordered}`}>
          <div className={styles.left} style={{ alignSelf: 'center' }}>
            <span>Procedimento(s)</span>
          </div>
          <div className={styles.right} style={{ paddingRight: '6px' }}>
            {procedureNames.map((pName, index) => <div key={`${pName}-${index}`}>{protocolName}{' > '}{pName}<br/></div>)}
          </div>
        </div>
        <div className={`${styles.table} ${styles.tableBordered}`}>
          <div className={styles.left}>
            <span>Status</span>
            <span>Nome do Paciente</span>
            <span>Consultório</span>
            <span>Data Agendada</span>
            <span>Obs. do Paciente</span>
            <span>Obs. do Agendamento</span>
            {files.map((f, idx) => (
              <span key={`name${idx}`}><FaRegFile />{f.filename}</span>
            ))}
          </div>
          <div className={styles.right}>
            <span>{Enums.statusSchedule[modalController.eventData?.status] ?? 'Não definido'}</span>
            <span>{modalController.eventData?.patient_name ?? '-'}</span>
            <span>{modalController.eventData?.clinic_name ?? '-'}</span>
            <span>{moment(modalController.eventData?.schedule_date).format('lll')}</span>
            <span title={modalController.eventData?.patient_observation}>{modalController.eventData?.patient_observation ?? '-'}</span>
            <span title={scheduleObs}>{scheduleObs}</span>
            {files.map((f, idx) => (
              <span key={`file${idx}`}>
                {f.id ? (
                  <a href={f.file} target='_blank' rel="noreferrer">Visualizar arquivo</a>
                ) : (
                  <a href="##" onClick={() => {
                    const w = window.open("");
                    if (f.file.includes('data:application/pdf')) {
                      w.document.write(`<iframe width='100%' height='100%' src='${f.file}'></iframe>`);
                    } else {
                      var image = new Image();
                      image.src = f.file;
                      w.document.write(image.outerHTML);
                    }
                  }}>Visualizar arquivo</a>
                )}
              </span>
            ))}
          </div>
        </div>
        <div className={`${styles.table} ${styles.tableSingleRowBordered}`}>
          <div className={styles.left} style={{ alignSelf: 'center' }}>
            <span>Novo Arquivo</span>
          </div>
          <div className={styles.right}>
            <span>
              <MuiButton component="label" style={{ paddingRight: 0 }}>
                {modalController.eventData?.filename == null ? (
                  <>
                    <MdFileUpload />
                    Anexar Arquivo
                  </>
                ) : modalController.eventData.filename}
                <input
                  hidden
                  onClick={() => {
                    handleSelectOneFile('.jpg, .jpeg, .png, .pdf', async (invoice_file, invoice_file64) => {
                      setLoading(true);
                      const data = await addEvent(
                        'addFile', 
                        {
                          schedule: modalController.eventData.id,
                          file: invoice_file,
                          filename: invoice_file.name,
                        }, 
                      );
                      if (data.error == null) {
                        setModalController(prevModalState => ({
                          ...prevModalState,
                          eventData: {
                            ...prevModalState.eventData,
                            files: [
                              ...prevModalState.eventData.files,
                              data,
                            ],
                          },
                        }))
                      }
                      setLoading(false);
                    });
                  }}
                />
              </MuiButton>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {modalController?.eventData?.status !== 'attended' && modalController?.eventData?.status !== 'canceled' ? (
          <>
            {modalController.eventData?.status !== 'didnotattend' && (
              <Button
                label="Paciente Não Compareceu"
                className={styles.button_didnotattend}
                onClick={async () => {
                  setLoading(true);
                  const success = await addEvent('didnotattend', modalController.eventData, setModalController);
                  setLoading(false);
                  if (success) {
                    onClose();
                  }
                }}
              />
            )}  
            <Button
              label="Cancelar"
              className={styles.button_cancel}
              onClick={() => setModalController(prevModalState => ({ ...prevModalState, event: 'cancel' }))}
            />
            <Button
              label="Reagendar"
              className={styles.button_reschedule}
              onClick={() => {
                const scheduleDate = moment(modalController.eventData.schedule_date);
                const scheduleDateEnd = moment(modalController.eventData.schedule_date_end);
                setModalController(prevModalState => ({ 
                  ...prevModalState, 
                  event: 'reschedule',
                  eventData: {
                    ...modalController.eventData,
                    schedule_date: scheduleDate.format('YYYY-MM-DD'),
                    hour: scheduleDate.format('HH:mm'),
                    hour_end: scheduleDateEnd ? scheduleDateEnd.format('HH:mm') : null,
                  }
                }));
              }}
            />
            <Button
              label="Editar"
              className={styles.button_edit}
              onClick={() => {
                const scheduleDate = moment(modalController.eventData.schedule_date);
                const scheduleDateEnd = moment(modalController.eventData.schedule_date_end);
                setModalController({ 
                  ...modalController, 
                  event: 'edit', 
                  eventData: {
                    ...modalController.eventData,
                    schedule_date: scheduleDate.format('YYYY-MM-DD'),
                    hour: scheduleDate.format('HH:mm'),
                    hour_end: scheduleDateEnd ? scheduleDateEnd.format('HH:mm') : null,
                  }
                });
              }}
            />
            {['scheduled', 'didnotattend'].includes(modalController.eventData?.status) && (
              <Button
                label="Paciente Chegou"
                className={styles.button_arrived}
                onClick={async () => {
                  setLoading(true);
                  const success = await addEvent('waiting', modalController.eventData, setModalController);
                  setLoading(false);
                  if (success) {
                    const schedule = modalController.eventData;
                    navigate(`/atendimento/prontuario/${schedule.patient}/consulta/${schedule.id}`);
                  }
                }}
              />
            )}              
          </>
        ) : (
          <Button label="Fechar" className={styles.button_add} onClick={() => onClose()} />
        )}
      </div>
    </Modal>
  );
}
