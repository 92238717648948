// hooks
import { useState, useContext, useEffect } from 'react';
import useFilters from './hooks/useFilters';
import useApi from './hooks/useApi';
import useScheduleHanlders from './hooks/useScheduleHanlders';
import useModalController from './hooks/useModalController';
import { UserRepository } from 'data/users';

// scss
import styles from './Schudule.module.scss';

// components
import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import EventModal from './EventModal/EventModal';
import { Calendar } from 'components/Calendar/Calender';
import { ClinicIdContext } from 'contexts/clinicId';
import { clinicalStorage } from 'shared/storage';
import PageTitle from 'components/PageTitle/PageTitle';

// icons
import { MdOutlineFilterAlt, MdFilterAlt  } from "react-icons/md";
import moment from 'moment';

export default function ConciergeSchedule() {
  const { clinicId } = useContext(ClinicIdContext);
  const clinicsList = JSON.parse(clinicalStorage());
  const { filters, setFilters, clearFilters } = useFilters();
  const { modalController, setModalController, onClose } = useModalController();
  const { defaultEvents, procedures, patients, addEvent, getSchedule } = useApi(filters);
  const { events, onDateClick, onEventClick, onDrop, onEventDrop } = useScheduleHanlders(
    defaultEvents,
    setModalController,
    getSchedule,
    filters
  );

  const clinicData = clinicsList.find(c => c.id.toString() === clinicId?.toString());
  
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    if (clinicId != null) {
      if (filters.procedure) {
        onClose(); //reseta os atributos do modal
      }
      loadProfessionals({});
      clearFilters(); //reseta os filtros, atualizando inclusive o clinicId
    }
  }, [clinicId]);

  const onModalClose = () => {
    setFilters({ ...filters });
    if (filters.procedure && filters.user) {
      onClose({ procedure: filters.procedure, user: filters.user });
    } else {
      onClose();
    }
  }

  const loadProfessionals = async selectedProcedure => {
    const filters = { clinic: clinicId };
    if (selectedProcedure.profile === 'doctor') {
      filters.user_type = '1';
    }
    if (selectedProcedure.profile === 'other' && selectedProcedure.service_type != null) {
      filters.user_type = '7';
      filters.service_type = selectedProcedure.service_type;
    }
    const userResponse = await UserRepository.list(filters);
    setProfessionals(userResponse?.results ?? []);
  }

  return <>
    <PageTitle className={styles.title}>
      Agenda
      {filtersIsVisible ? (
        <MdFilterAlt onClick={() => setFiltersIsVisible(!filtersIsVisible)} />
      ) : (
        <MdOutlineFilterAlt onClick={() => setFiltersIsVisible(!filtersIsVisible)} />
      )}
      <Button
        label="+ Adicionar"
        variant="contained"
        color="secondary"
        onClick={() => {
          const now = moment();
          setModalController(prevState => ({
            ...prevState,
            open: true,
            event: 'add',
            eventData: {
              schedule_date: now.format('YYYY-MM-DD'),
              hour: now.format('HH:mm'),
              hour_end: now.add(30, 'minutes').format('HH:mm'),
            },
          }));        
        }}
      />
    </PageTitle>
    <Container className={styles.container}>
      {
        filtersIsVisible && (
          <div className={styles.action_bar}>
            <Autocomplete
              className={styles.autocomplete}
              label="Procedimento"
              value={procedures
                .map(item => ({ label: item.first_name, value: item.id }))
                .find(item => item.value === filters.procedure)}
              options={procedures.map(item => ({ label: item.name, value: item.id }))}
              onChange={(_, item) => {
                if (item != null) {
                  const selectedProcedure = procedures.find(procedure => procedure.id === item.value);
                  loadProfessionals(selectedProcedure);
                  setModalController(prevState => ({ ...prevState, eventData: { ...prevState.eventData, procedure: item.value } }));
                  setFilters(prevFilters => ({ ...prevFilters, procedure: item.value }));
                }
              }}
            />
            <Autocomplete
              className={styles.autocomplete}
              label="Profissional"
              value={professionals
                .map(user => ({ label: user.first_name, value: user.id }))
                .find(user => user.value === filters.user)}
              options={professionals
                .map(user => {
                  const label = `${user.first_name} ${user.last_name}`
                  return { label: label, value: user.id };
                })
                .filter(option => option !== null)}
              onChange={(_, item) => {
                if (item != null) {
                  setFilters(prevFilters => ({ ...prevFilters, user: item.value }));
                  setModalController(prevState => ({ ...prevState, eventData: { ...prevState.eventData, user: item.value } }));
                }
              }}
            />
            <Button
              label="Limpar Filtros"
              onClick={() => {
                loadProfessionals({});
                clearFilters();
              }}
            />
          </div>
        )
      }
      <div className={styles.calendar_container}>
        <Calendar
          events={events.map(e => ({ ...e, resourceId: e.user?.toString() ?? '' }))}
          onDateClick={onDateClick}
          onEventClick={onEventClick}
          onDrop={onDrop}
          onEventDrop={onEventDrop}
          resources={professionals.map(p => ({
            id: p.id.toString(), 
            title: `${p.first_name} ${p.last_name}`
          }))}
          customHeaderToolbar={{
            left: 'prev,today,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,resourceTimeGridDay,listMonth',
          }}
          buttonText={{
            day: 'Profissionais'
          }}
          customFooterToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          slotMinTime={clinicData?.opening}
          slotMaxTime={clinicData?.closure}
        />
      </div>
      {professionals.length > 0 && (
        <EventModal props={{ modalController, setModalController, onModalClose, filters, addEvent, patients, procedures, professionals }} />
      )}
    </Container>
  </>;
}
