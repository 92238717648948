import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './Prontuario.module.scss';
import Button from 'components/Button/Button';
import { LoaderContext } from 'contexts/loader';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import Enums from 'transformers/enums';
import moment from 'moment';
import { PatientsRepository } from 'data/patients';
import { AuthContext } from 'contexts/auth';
import { useToast } from 'hooks/useToast';
import Modal from 'components/Modal/Modal';
import Input from 'components/Input/Input';
import { ProtocolsRepository } from 'data/protocols';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { DiseasesRepository } from 'data/diseases';
import { ClinicIdContext } from 'contexts/clinicId';
import { ProceduresRepository } from 'data/procedures';
import Card from 'components/Card/Card';
import { SchedulesRepository } from 'data/schedules';
import Select from 'components/Select/Select';
import { transformApiToMedicalRecords } from 'transformers/userForm';
import Table from 'components/Table/Table';
import { StockRepository } from 'data/stock';
import TabSelector from './TabSelector';
import { IoMdTrash } from 'react-icons/io';
import { BiSolidFilePdf } from 'react-icons/bi';
import { FaFileImage } from 'react-icons/fa';
import { handleSelectOneFile } from 'shared/utility';
import { Button as MuiButton } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { TemplatesRepository } from 'data/templates';

const initialFormDataProcedures = {
  order: 0,
  procedure: null,
  min_days: null,
  max_days: null,
};

function Prontuario() {
  const toast = useToast();
  const params = useParams();
  const { clinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const editorAnamneseRef = useRef(null);
  const editorEvolutionRef = useRef(null);
  const editorPatientObsRef = useRef(null);

  const [modalStatus, setModalStatus] = useState({ open: false, id: '', item: '' });
  const [diseases, setDiseases] = useState([]);
  const [patient, setPatient] = useState({});
  const [patientDiseases, setPatientDiseases] = useState([]);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [patientProtocols, setPatientProtocols] = useState([]);
  const [openProtocol, setOpenProtocol] = useState({ open: false, id: null });
  const [protocolList, setProtocolList] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [scheduleProducedureItems, setScheduleProducedureItems] = useState({
    usedItems: [],
    stocksSelected: [],
  });
  const [formDisease, setFormDisease] = useState({
    patient: parseInt(params.patientId),
    disease: null,
    diagnosis_date: moment().format('YYYY-MM-DD'),
    discharge_date: null,
    user: user.id,
  });

  const [formMedical, setFormMedical] = useState({
    schedule: null,
    patient: params.patientId,
    user: user.id,
    service_date: '',
    heart_rate: '',
    respiratory_frequency: '',
    abdominal_circumference: '',
    capillary_glycemia: '',
    blood_pressure: '',
    saturation: '',
    weight: '',
    height: '',
    bmi: '',
    evolution: '',
  });

  const [formProtocol, setFormProtocol] = useState({
    patient: null,
    protocol: null,
    original_value: null,
    is_payed: false,
    procedures: [],
  });
  const [proceduresList, setProceduresList] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [procedureId, setProcedureId] = useState({});
  const [priceProcedures, setPriceProcedures] = useState(0);
  const scheduleExists = medicalRecords.find(mr => mr.schedule?.toString() === params.scheduleId?.toString()) != null;
  const isReadOnly = scheduleExists && scheduleData?.is_finished;
  const isDoctor = user?.user_types?.includes(Enums.profileNameToId.DOCTOR);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ProceduresRepository.list(formProtocol.protocol);
      setProcedures(response.results);
      setLoading(false);
    };
    if (formProtocol.protocol) run();
  }, [formProtocol.protocol]);

  useEffect(() => {
    if (clinicId != null) {
      getPatientData();
    }
  }, [clinicId]);

  const getPatientData = async () => {
    let diseaseList = [];
    setLoading(true);
    const responses = await Promise.all([
      PatientsRepository.get(params.patientId), 
      PatientsRepository.getDiseases(params.patientId), 
      PatientsRepository.getProtocolos(params.patientId, { clinic: clinicId }),
      PatientsRepository.listMedicalRecords(params.patientId),
      DiseasesRepository.list({ limit: 999 }),
      SchedulesRepository.get(params.scheduleId),
      ProtocolsRepository.list(clinicId),
      TemplatesRepository.list(clinicId)
    ]);
    const patientsResponse = responses[0];
    const diseasesResponse = responses[1];
    const protocolsResponse = responses[2];
    const medicalResponse = responses[3];
    const responseDiseases = responses[4];
    const responseScheduleData = responses[5];
    const responseProtocols = responses[6];
    const responseTemplates = responses[7];

    if (patientsResponse) {
      setPatient(patientsResponse);
    }

    if (responseProtocols?.results) {
      setProtocolList(responseProtocols.results);
    }

    if (responseScheduleData) {
      setScheduleProducedureItems({ ...scheduleProducedureItems, usedItems: responseScheduleData.procedure_items });
      setScheduleData(responseScheduleData);
    }

    if (responseDiseases.results) {
      setDiseases(responseDiseases.results);
    }

    if (medicalResponse.results) {
      setMedicalRecords(medicalResponse.results);
      medicalResponse.results.forEach(medical => {
        if (medical?.schedule === parseInt(params.scheduleId)) {
          setFormMedical(transformApiToMedicalRecords({ ...medical }));
        }
      });
    }

    if (protocolsResponse.results) {
      let newProtocolPatient = [];
      responseProtocols.results.forEach(item => {
        protocolsResponse.results.forEach(proto => {
          if (item.id === proto.protocol) {
            newProtocolPatient.push({ ...proto, protocols_name: item.protocols_name });
          }
        });
      });
      setPatientProtocols(newProtocolPatient);
    }

    if (diseasesResponse.results) {
      diseasesResponse.results.forEach(item => {
        responseDiseases.results.forEach(disease => {
          if (item.disease === disease.id) diseaseList.push({ name: disease.name, ...item });
        });
      });
      setPatientDiseases(diseaseList);
    }

    if (responseTemplates?.results) {
      setTemplatesList(responseTemplates.results);
    }

    setLoading(false);
  };

  const handleSubmit = is_finished => {
    try {
      if (formMedical.id == null) {
        for (const item of scheduleProducedureItems.usedItems) {
          let remainingQtd = item.quantity;
          scheduleProducedureItems.stocksSelected.filter(e => e.item_procedure === item.id).forEach(e => remainingQtd -= e.quantity);
          if (remainingQtd > 0) {
            toast.error(`Ainda faltam informar ${remainingQtd} unidades de ${item.item_name}`);
            return;
          }
        }
      }
      saveMedicalRecord(is_finished, scheduleProducedureItems.stocksSelected);
    } catch (error) {}
  };

  const saveMedicalRecord = async (is_finished, stocks) => {
    setLoading(true);
    const nullableFields = [
      'service_date',
      'heart_rate',
      'respiratory_frequency',
      'abdominal_circumference',
      'capillary_glycemia',
      'blood_pressure',
      'saturation',
      'weight',
      'height',
      'bmi',
    ];
    const evolutionText = editorEvolutionRef.current?.getContent();
    setFormMedical({ ...formMedical, evolution: evolutionText });
    const formMedicalPayload = { 
      ...formMedical, 
      is_finished: is_finished,
      evolution: evolutionText,
    };
    for (const nullableField of nullableFields) {
      if (formMedicalPayload[nullableField] === '') {
        formMedicalPayload[nullableField] = null;
      }
    }

    const newAnamnesis = editorAnamneseRef.current?.getContent() ?? patient.anamnesis;
    const newIndividualObs = editorPatientObsRef.current?.getContent() ?? patient.individual_obs;
    if (newAnamnesis !== patient.anamnesis || newIndividualObs !== patient.individual_obs) {
      await PatientsRepository.update(patient.id, { 
        anamnesis: newAnamnesis,
        individual_obs: newIndividualObs,
      });
    }

    const response = formMedicalPayload.id
      ? await PatientsRepository.editMedicalRecords(params.patientId, formMedicalPayload.id, formMedicalPayload)
      : await PatientsRepository.createMedicalRecords(params.patientId, {
          ...formMedicalPayload,
          stocks: stocks,
          schedule: parseInt(params.scheduleId),
          service_date: moment().format('YYYY-MM-DD'),
        });
        
    if (is_finished && response.error == null) {
      await SchedulesRepository.editSchedule(params.scheduleId, { status: 'attended' });
    }
    setLoading(false);
    if (response.error) {
      toast.error('Algo deu errado');
    } else {
      toast.success('Dados salvos com sucesso');
      if (is_finished) {
        window.location.href = '/atendimento/lista-de-espera';
      }
    }
  };

  const handleAddItem = async () => {
    const newProcedureItem = { 
      ...initialFormDataProcedures, 
      procedure: procedureId.value, 
      procedure_name: procedureId.name 
    };
    if (proceduresList.length === 0) {
      newProcedureItem.min_days = 0;
      newProcedureItem.max_days = 0;
    }
    const newProceduresList = [ ...proceduresList, newProcedureItem ];
    let newPriceProdures = priceProcedures ?? 0;
    setProceduresList(newProceduresList);
    procedures.forEach(item => {
      if (item.id === procedureId.value) {
        newPriceProdures += item.value;
      }
    });
    setPriceProcedures(newPriceProdures);
    setFormProtocol(prevFormProtocol => ({
      ...prevFormProtocol,
      procedures: newProceduresList,
      patient: params.patientId,
      original_value: newPriceProdures,
    }));
    setProcedureId({});
  };

  function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  const handleChangeProcedures = (index, event) => {
    const { name } = event.target;
    const { value } = event.target;
    const updatedForms = [...proceduresList];
    updatedForms[index][name] = value;
    setFormProtocol(prevFormProtocol => ({
      ...prevFormProtocol,
      procedures: updatedForms,
    }));
  };

  const handleSubmitProtocols = async () => {
    try {
      saveProtocol();
    } catch (error) {
      toast.error(error);
    }
  };

  const saveDisease = async editId => {
    setLoading(true);
    const response = editId
      ? await PatientsRepository.editDisease(params.patientId, editId, formDisease)
      : await PatientsRepository.createDisease(params.patientId, formDisease);
    setLoading(false);
    if (response?.error) {
    } else {
      getPatientData();
      setFormDisease({
        patient: parseInt(params.patientId),
        disease: null,
        diagnosis_date: moment().format('YYYY-MM-DD'),
        discharge_date: null,
        user: user.id,
      });
      setModalStatus({ open: false, id: '', item: '' });
    }
  };

  const handleDeleteDisease = async editId => {
    setLoading(true);
    const response = PatientsRepository.deleteDisease(params.patientId, editId);
    setLoading(false);
    if (response?.error) {
    } else {
      getPatientData();
      setModalStatus({ open: false, id: '', item: '' });
    }
  };

  const saveProtocol = async () => {
    setLoading(true);
    const response = await PatientsRepository.postProtocols(params.patientId, formProtocol);
    setLoading(false);
    if (response.error) {
    } else {
      setFormProtocol({
        patient: null,
        protocol: null,
        original_value: null,
        is_payed: false,
        procedures: [],
      });
      setProceduresList([]);
      setProcedureId({});
      setPriceProcedures(0);
      getPatientData();
      setModalStatus({ open: false, id: '', item: '' });
    }
  };

  const templatesAnamnese = templatesList.filter(t => t.text_type === 'anamnese').map(t => ({ value: t.id, name: t.name }));
  const templatesEvolution = templatesList.filter(t => t.text_type === 'evolution').map(t => ({ value: t.id, name: t.name }));
  const patientAnamnesis = patient.anamnesis ?? '';
  const patientIndividualObs = patient.individual_obs ?? '';
  const procedureNames = (scheduleData?.procedure_name ?? '-').split(', ');
  return (<>
    <Grid container spacing={2} className={styles.globalGrid}>
      <Grid item xs={12}>
      <div className={styles.container}>
        <Grid container>
          <Grid item xs={5.9}>
            <TabSelector 
              options={{
                'Dados Pessoais': <>
                  <div className={styles.bgGrid}>
                    <div className={styles.bgVividBordered}>
                      <b>Paciente: </b>{patient.name}
                    </div>
                    <div className={styles.bgVividBordered}>
                      <b>Sexo: </b>{Enums.patientGender[patient.gender]}
                    </div>
                  </div>
                  <div className={styles.bgGrid}>
                    <div className={styles.bgVividBordered}>
                      <b>Data de Nascimento: </b>{`${moment(patient.birthdate).format('DD/MM/YYYY')} - ${getAge(patient.birthdate)} Anos`}
                    </div>
                    <div className={styles.bgVividBordered}>
                      <b>Rede Social: </b>{patient.social_url ? <a href={patient.social_url} target='_blank' rel="noreferrer">Abrir em nova guia</a> : <i>Não informado</i>}
                    </div>
                  </div>
                  <div className={styles.bgVividBordered}>
                    <b>Observação: </b>{patient.observation}
                  </div>
                </>, 
                'Diagnósticos': <>
                  {patientDiseases.sort((a,b) => b.id - a.id).map((item, index) => (
                    <div 
                      className={styles.problemItem}
                      style={index % 2 === 1 ? { backgroundColor: '#ffffff' } : undefined}
                      key={`problem-${index}`}
                    >
                      <div>{item.name} - Diagnóstico {moment(item.diagnosis_date).format('DD/MM/YYYY')}</div>
                      <div>
                        {item.discharge_date == null ? (
                          <Button 
                            label="Remover" 
                            variant="text"
                            disabled={isReadOnly}
                            onClick={() => setModalStatus({ open: true, id: 'RemoveProblem', item: item })} 
                          />
                        ) : `Removido ${moment(item.discharge_date).format('DD/MM/YYYY')}`}
                      </div>
                    </div>
                  ))}
                  <Button 
                    label="Adicionar Outro Diagnóstico" 
                    className={styles.addOtherProblemButton}
                    disabled={isReadOnly}
                    onClick={() => {
                      setFormDisease({
                        patient: parseInt(params.patientId),
                        disease: null,
                        diagnosis_date: moment().format('YYYY-MM-DD'),
                        discharge_date: null,
                        user: user.id,
                      });
                      setModalStatus({ open: true, id: 'AddProblem' }); 
                    }}
                  />
                </>,
                'Anamnese': Object.keys(patient) === 0 ? '' : <div>
                  <Select
                    label={`Carregar a partir do template:${patientAnamnesis.length > 0 ? ' (redefine o texto de anamnese)' : ''}`}
                    options={templatesAnamnese}
                    className={styles.editorSelect}
                    onChange={e => {
                      const template = templatesList.find(t => t.id === e.target.value);
                      setPatient({ ...patient, anamnesis: template.text });
                      editorAnamneseRef.current.setContent(template.text);
                    }}
                  />
                  <Editor
                    disabled={isReadOnly || !isDoctor}
                    onInit={(evt, editor) => (editorAnamneseRef.current = editor)}
                    initialValue={patientAnamnesis}
                    init={{
                      language: 'pt_BR',
                      language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/pt_BR.js',
                      statusbar: false,
                      menubar: false,
                      height: 400,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste imagetools wordcount',
                      ],
                      toolbar:
                        'insertfile undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      image_title: true,
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();
                          reader.onload = function () {
                            var id = 'blobid' + new Date().getTime();
                            var blobCache = editorAnamneseRef.current.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            cb(blobInfo.blobUri(), { title: file.name });
                          };
                          reader.readAsDataURL(file);
                        };
                        input.click();
                      },
                  }}
                />
              </div>, 
            }} />
          </Grid>
          <Grid item xs={0.2}>
            <div className={styles.individualObsSeparator}/>
          </Grid>
          <Grid item xs={5.9}>
            <TabSelector 
              optionsWidth="30vw"
              bordered={true}
              options={{
                'Minha observação do paciente': (
                  <div className={styles.individualObsDiv}>
                    <small>Este texto é visível somente a você!</small>
                    <Editor
                      onInit={(evt, editor) => (editorPatientObsRef.current = editor)}
                      initialValue={patientIndividualObs}
                      init={{
                        language: 'pt_BR',
                        language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/pt_BR.js',
                        statusbar: false,
                        menubar: false,
                        height: 300,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste imagetools wordcount',
                        ],
                        toolbar:
                          'insertfile undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        image_title: true,
                        automatic_uploads: true,
                        file_picker_types: 'image',
                        file_picker_callback: function (cb, value, meta) {
                          var input = document.createElement('input');
                          input.setAttribute('type', 'file');
                          input.setAttribute('accept', 'image/*');
                          input.onchange = function () {
                            var file = this.files[0];
                            var reader = new FileReader();
                            reader.onload = function () {
                              var id = 'blobid' + new Date().getTime();
                              var blobCache = editorPatientObsRef.current.editorUpload.blobCache;
                              var base64 = reader.result.split(',')[1];
                              var blobInfo = blobCache.create(id, file, base64);
                              blobCache.add(blobInfo);
                              cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                          };
                          input.click();
                        },
                      }}
                    />
                  </div>
                )
              }}
            />
          </Grid>
        </Grid>
      </div>
      </Grid>
    </Grid>
    <Grid container className={styles.globalGrid}>
      <Grid item xs={12}>
        <div className={styles.container}>
          <div className={styles.containerTitle} style={{ width: '100%' }}>
            <h4>Agenda</h4>
            <div className={styles.scheduleDateSeparator} />
            <div className={styles.scheduleDate}>
              {moment(scheduleData?.schedule_date).format('HH:mm DD/MM/YYYY')}
            </div>
          </div>
          <Grid container spacing={2} className={styles.agendaGrid}>
            <Grid item xs={6}>
              <div className={styles.borderedContainer}>
                <div className={styles.borderedContainerTitle}>
                  Agendamento
                </div>
                {procedureNames.map((pName, index) => <div key={`${pName}-${index}`}>{scheduleData.protocol_name}{' > '}{pName}<br/></div>)}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.borderedContainer}>
                <div className={styles.borderedContainerTitle}>
                  Observação do Agendamento
                </div>
                {scheduleData.description ?? <i>Não informado</i>}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
    <Grid container spacing={2} className={styles.globalGrid}>
      <Grid item xs={6}>
        <div className={styles.container}>
          <div className={styles.containerTitle}>
            <h4>Prontuário</h4>
          </div>
          {(scheduleData.files ?? []).length > 0 && (
            <div className={styles.prontuario}>
              <div className={styles.prontuarioTitle}>
                Arquivos anexados a este Atendimento
                {!isReadOnly && (
                  <IoMdTrash onClick={async () => {
                    setLoading(true);
                    for (const file of scheduleData.files) {
                      const response = await SchedulesRepository.deleteScheduleFile(scheduleData.id, file.id);
                      if (response.error == null) {
                        const newFiles = [...scheduleData.files];
                        newFiles.splice(newFiles.indexOf(file), 1);
                        setScheduleData({
                          ...scheduleData,
                          files: newFiles,
                        });
                      }
                    }
                    setLoading(false);
                  }} />
                )}                
              </div>
              {scheduleData.files.map(file => {
                const isPDF = file.file.split('.').pop().toUpperCase() === 'PDF';
                return (
                  <div 
                    className={styles.prontuarioFileName} 
                    onClick={() => window.open(file.file, '__blank')}
                  >
                    {isPDF ? <BiSolidFilePdf /> : <FaFileImage />}
                    {file.filename}
                  </div>
                );
              })}
            </div>
          )}
          {medicalRecords.map((medicalRecord, index) => {
            const sinaisVitaisFields = {
              'heart_rate': 'Frequência Cardíaca', 
              'respiratory_frequency': 'Frequência Respiratória', 
              'capillary_glycemia': 'Glicemia capilar', 
              'abdominal_circumference': 'Circunferência abdominal', 
              'blood_pressure': 'Pressão arterial', 
              'saturation': 'Saturação', 
              'weight': 'Peso', 
              'height': 'Altura', 
              'bmi': 'IMC'
            };
            const sinaisVitais = [];
            Object.keys(sinaisVitaisFields).forEach(field => {
              if (medicalRecord[field] != null) {
                sinaisVitais.push(`${sinaisVitaisFields[field]}: ${medicalRecord[field]}`);
              }
            });
            return (
              <div key={`medicalRecords-${index}`} className={styles.prontuario}>
                <div className={styles.prontuarioTitle}>
                {`${moment(medicalRecord.service_date).format('DD/MM/YYYY')} - ${medicalRecord.professional_name}`}
                </div>
                {medicalRecord.schedule_files?.map(file => {
                  const isPDF = file.file.split('.').pop().toUpperCase() === 'PDF';
                  return (
                    <div 
                      className={styles.prontuarioFileName} 
                      onClick={() => window.open(file.file, '__blank')}
                    >
                      {isPDF ? <BiSolidFilePdf /> : <FaFileImage />}
                      {file.filename}
                    </div>
                  );
                })}
                <div className={styles.prontuarioSubtitle}>
                  <h5>
                    <span>Evolução</span>
                    {`${medicalRecord.schedule_protocol_name} > ${medicalRecord.schedule_procedure_name ?? ''}`}
                  </h5>
                  <div 
                    className={styles.prontuarioSubtitleContent} 
                    dangerouslySetInnerHTML={{__html: medicalRecord?.evolution ?? 'Sem conteúdo adicionado'}} 
                  />
                </div>            
                {sinaisVitais.length > 0 && (
                  <div className={styles.prontuarioSubtitle}>
                    <h5>
                      <span>Sinais Vitais</span>
                    </h5>
                    <div className={styles.prontuarioSubtitleContent}>
                      {sinaisVitais.map(item => <p>{item}</p>)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={styles.container}>
          <div className={styles.containerTitle}>
            <h4>Consulta</h4>
          </div>
          <TabSelector
            bordered={true} 
            options={{
              'Evolução': <div>
                <Select
                  label={`Carregar a partir do template:${formMedical.evolution.length > 0 ? ' (redefine o texto de evolução)' : ''}`}
                  options={templatesEvolution}
                  className={styles.editorSelect}
                  onChange={e => {
                    const template = templatesList.find(t => t.id === e.target.value);
                    setPatient({ ...patient, anamnesis: template.text });
                    setFormMedical({ ...formMedical, evolution: template.text });
                    editorEvolutionRef.current.setContent(template.text);
                  }}
                />
                <Editor
                  disabled={isReadOnly}
                  onInit={(evt, editor) => (editorEvolutionRef.current = editor)}
                  initialValue={formMedical.evolution}
                  init={{
                    language: 'pt_BR',
                    language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/pt_BR.js',
                    statusbar: false,
                    menubar: false,
                    height: 400,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste imagetools wordcount',
                    ],
                    toolbar:
                      'insertfile undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                      var input = document.createElement('input');
                      input.setAttribute('type', 'file');
                      input.setAttribute('accept', 'image/*');
                      input.onchange = function () {
                        var file = this.files[0];
                        var reader = new FileReader();
                        reader.onload = function () {
                          var id = 'blobid' + new Date().getTime();
                          var blobCache = editorEvolutionRef.current.editorUpload.blobCache;
                          var base64 = reader.result.split(',')[1];
                          var blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);
                          cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                      };
                      input.click();
                    },
                  }}
                />
              </div>, 
              'Sinais Vitais': <div className={styles.sinaisVitais}>
                <Input
                  label="Frequência Cardíaca"
                  value={formMedical.heart_rate}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, heart_rate: e.target.value });
                  }}
                />
                <Input
                  label="Glicemia capilar"
                  value={formMedical.capillary_glycemia}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, capillary_glycemia: e.target.value });
                  }}
                />
                <Input
                  label="Pressão arterial"
                  value={formMedical.blood_pressure}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, blood_pressure: e.target.value });
                  }}
                />
                <Input
                  label="Peso (em kg)"
                  type="number"
                  value={formMedical.weight}
                  onChange={e => {
                    setFormMedical({ ...formMedical, weight: e.target.value });
                  }}
                />
                <Input
                  label="Altura (em cm)"
                  type="number"
                  value={formMedical.height}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, height: e.target.value });
                  }}
                />
                <Input
                  label="IMC"
                  type="number"
                  value={formMedical.bmi}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, bmi: e.target.value });
                  }}
                />
                <Input
                  label="Frequência Respiratória"
                  value={formMedical.respiratory_frequency}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, respiratory_frequency: e.target.value });
                  }}
                />
                <Input
                  label="Circunferência abdominal"
                  value={formMedical.abdominal_circumference}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, abdominal_circumference: e.target.value });
                  }}
                />
                <Input
                  label="Saturação"
                  value={formMedical.saturation}
                  disabled={isReadOnly}
                  onChange={e => {
                    setFormMedical({ ...formMedical, saturation: e.target.value });
                  }}
                />
              </div>,
              [!scheduleExists && scheduleData.procedure_items && scheduleData.procedure_items.length > 0 ? 'Insumos' : '']: (
                <ItemsComponent 
                  scheduleProducedureItems={scheduleProducedureItems} 
                  setScheduleProducedureItems={setScheduleProducedureItems} 
                  isReadOnly={isReadOnly}
                />
              )
            }} 
            style={{ marginTop: '30px' }}
          />
          {!isReadOnly && (
            <div className={styles.scheduleActionsButtons}>
              <MuiButton component="label" className={styles.uploadFileButton}>
                Upload Arquivo
                <input
                  hidden
                  onClick={() => {
                    handleSelectOneFile('.jpg, .jpeg, .png, .pdf', async (invoice_file, invoice_file64) => {
                      setLoading(true);
                      const response = await SchedulesRepository.editSchedule(scheduleData.id, { file_0: invoice_file });
                      if (response.error) {
                        toast.error(response.error);
                      } else {
                        const newFiles = await SchedulesRepository.getScheduleFiles(scheduleData.id);
                        if (newFiles.error == null) {
                          setScheduleData({
                            ...scheduleData,
                            files: newFiles.results ?? [],
                          });
                        }
                      }
                      setLoading(false);
                    });
                  }}
                />
              </MuiButton>
              <div className={styles.saveDiv}>
                <Button 
                  label="Salvar" 
                  color="dark"
                  onClick={() => {
                    handleSubmit(false);             
                  }}
                />
                <Button 
                  label="Finalizar Consulta" 
                  color="secondary"
                  onClick={() => {
                    handleSubmit(true);             
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {user.user_types.includes(1) && !isReadOnly && (
          <Button 
            label="Protocolos" 
            className={styles.protocolButton}
            onClick={() => setModalStatus({ open: true, id: 'ViewProtocolos' })}
          />
        )}
      </Grid>
    </Grid>
    {/*-------------------------------------------- Modal de adicionar um diagnóstico ------------------------------------------------------------*/}
    <Modal
      className={styles.modalAddProblem}
      isOpen={modalStatus.open && modalStatus.id === 'AddProblem'}
      setModalOpen={() => setModalStatus({ open: false, id: '', item: '' })}
    >
      <div className={styles.containerModalAddProblem}>
        <h1>Adicionar um Diagnóstico</h1>
        <Grid container spacing={2} className={styles.gridModalAddProblem}>
          <Grid item xs={5}>
            <Autocomplete
              label="Diagnóstico"
              value={diseases
                .map(item => ({ label: item.name, value: item.id }))
                .find(item => item.value === formDisease.disease)}
              options={diseases.sort((a, b) => a.name - b.name).map(item => ({ value: item.id, label: item.name }))}
              onChange={(_, item) => {
                setFormDisease({ ...formDisease, disease: item.value });
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              type="date"
              name="date"
              label="Data Diagnóstico"
              value={formDisease.diagnosis_date}
              onChange={e => setFormDisease({ ...formDisease, diagnosis_date: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <Button label="Adicionar" color="secondary" onClick={() => saveDisease()} />
          </Grid>
        </Grid>
        <div className={styles.modalAddProblemBtnAdd}></div>
      </div>
    </Modal>
    {/*------------------------------------- Modal de remover ou adicionar um data de saida de um diagnóstico -------------------------------------------*/}
    <Modal
      className={styles.modalRemoveProblem}
      size="sm"
      isOpen={modalStatus.open && modalStatus.id === 'RemoveProblem'}
      setModalOpen={() => setModalStatus({ open: false, id: '', item: '' })}
    >
      <div className={styles.containerModalRemoveProblem}>   
        {modalStatus.id2 === 'Cured' ? (
          <>
            <h2>
              Informe a data da cura:
            </h2> 
            <Input
              type="date"
              name="date"
              className={styles.inputRemoveProblem}
              value={formDisease.discharge_date}
              onChange={e => setFormDisease({ discharge_date: e.target.value })}
            />
            <Button label="Salvar Exclusão" onClick={() => saveDisease(modalStatus.item?.id)} color="secondary" />
          </>
        ) : (
          <>
            <h2>
              Qual o motivo da remoção?
            </h2> 
            <div className={styles.buttonsModalRemoveProblem}>
              <Button label="Erro de Cadastro" onClick={() => handleDeleteDisease(modalStatus.item?.id)} />  
              <Button label="Paciente Curado" onClick={() => setModalStatus({ ...modalStatus, id2: 'Cured'})} />
            </div> 
          </>
        )}   
      </div>
    </Modal>
    {/*------------------------------------------------ Visualizar os Protocolos do paciente -----------------------------------------------------------*/}
    <Modal
      className={styles.modalViewProtocol}
      isOpen={modalStatus.open && modalStatus.id === 'ViewProtocolos'}
      setModalOpen={() => setModalStatus({ open: false, id: '', item: '' })}
    >
      <div className={styles.headerModalViewProtocolo}>
        <p>Protocolos</p>
        <Button 
          label="adicionar" 
          onClick={() => setModalStatus({ open: true, id: 'AddProtocolos' })} 
          size="sm"
        />
      </div>
      <div className={styles.outsideContainerViewProtocol}>
        {patientProtocols.map(item => (
          <div className={styles.containerViewProtocol}>
            <div className={styles.contentHeaderModalViewProtocolo}>
              <Button
                onClick={() => {
                  const newOpen = item.id === openProtocol.id ? !openProtocol.open : true;
                  setOpenProtocol({ open: newOpen, id: item.id });
                }}
                label={item.id === openProtocol.id && openProtocol.open === true ? '-' : '+'}
              />
              <p>{moment(item.created_at).format('DD/MM/YYYY')}</p>
              <p>{item.protocols_name}</p>
            </div>
            {item.id === openProtocol.id && openProtocol.open && (
              <Table
                columns={['Procedimento', 'Data Agendada', 'Profissional', 'Status']}
                lines={item.procedures.map(procedure => [
                  procedure.procedure_name,
                  procedure?.schedule_date ? moment(procedure.schedule_date).format('DD/MM/YYYY') : 'Não Agendado',
                  procedure.professional_name ?? '-',
                  procedure.schedule_status ? Enums.statusSchedule[procedure.schedule_status] :  '-'
                ])}
              />
            )}  
          </div>
        ))}
      </div>
    </Modal>
    {/*--------------------------------------------- Adicionar um novo Protocolo para o paciente --------------------------------------------------------*/}
    <Modal
      className={styles.modalAddProtocolo}
      isOpen={modalStatus.open && modalStatus.id === 'AddProtocolos'}
      setModalOpen={() => setModalStatus({ open: false, id: '', item: '' })}
    >
      <h1>Adicionar um protocolo</h1>
      <div className={styles.AddProtocoloHeader}>
        <Select
          label="Protocolo"
          options={protocolList.map(item => ({ name: item.protocols_name, value: item.id }))}
          value={formProtocol.protocol}
          onChange={e => setFormProtocol({ ...formProtocol, protocol: e.target.value })}
          disabled={formProtocol.protocol === null ? false : true}
        />
        <Select
          label="Procedimentos"
          value={procedureId.value}
          options={
            formProtocol.protocol
              ? procedures.map(item => ({ name: item.name, value: item.id }))
              : [{ name: 'Selecione um protocolo' }]
          }
          onChange={(_, item) =>
            setProcedureId({
              ...procedureId,
              value: item.props.value,
              name: item.props.name,
            })
          }
        />
        <Button
          label="+"
          color="secondary"
          onClick={() => handleAddItem()}
          disabled={procedureId.value ? false : true}
        />
      </div>
      <Card className={styles.cardContainerAddProtocolo}>
        {proceduresList.map((form, index) => (
          <Grid container spacing={2} className={styles.proceduresForm}>
            <Grid item xs={4}>
              <p>{form.procedure_name}</p>
            </Grid>
            {index !== 0 && (
              <>
                <Grid item xs={4}>
                  <Input
                    type="number"
                    name="min_days"
                    label="Fazer após X dias (min)"
                    value={form.min_days}
                    onChange={e => handleChangeProcedures(index, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type="number"
                    name="max_days"
                    label="Fazer após X dias (máx)"
                    value={form.max_days}
                    onChange={e => handleChangeProcedures(index, e)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Card>
      <div className={styles.btnsModalAddProtocolo}>
        <p>Total: R$ {priceProcedures}</p>
        <Button
          disabled={proceduresList[0] ? false : true}
          label="Salvar"
          onClick={() => {
            handleSubmitProtocols();
          }}
        />
      </div>
    </Modal>
  </>);
}

const ItemsComponent = ({ scheduleProducedureItems, setScheduleProducedureItems, isReadOnly }) => {

  const { clinicId } = useContext(ClinicIdContext);
  const [tabInfo, setTabInfo] = useState(scheduleProducedureItems.usedItems[0].id);
  const [qtdValue, setQtdValue] = useState('');
  const [selectedStock, setSelectedStock] = useState(null);
  const [stock, setStock] = useState(null);

  useEffect(() => {
    const run = async () => {
      setStock(null);
      setSelectedStock(null);
      setQtdValue('');
      const prodedureItem = scheduleProducedureItems.usedItems.find(oi => oi.id === tabInfo);
      const response = await StockRepository.list({ clinic: clinicId, item: prodedureItem.item });
      setStock(response?.results ?? []);
    };
    if (tabInfo != null && clinicId != null) {
      run();
    }
  }, [tabInfo]);

  const stockBatchQtd = {};
  const stockOptions = [];
  if (stock != null && tabInfo != null) {
    stock.forEach(stockItem => {
      let qtd = Math.trunc(stockItem.balance);
      scheduleProducedureItems.stocksSelected.filter(e => e.stock === stockItem.id).forEach(e => qtd -= e.quantity);
      stockBatchQtd[stockItem.id] = qtd;
      stockOptions.push({
        label: `Lote: ${stockItem.batch}, Qtd: ${qtd}`,
        value: stockItem.id,
      });
    });
  }

  let remainingQtd = 0;
  const selectedItem = scheduleProducedureItems.usedItems.find(e => e.id === tabInfo);
  const currentStocksSelected = scheduleProducedureItems.stocksSelected.filter(e => e.item_procedure === tabInfo);
  if (tabInfo != null) {
    remainingQtd = selectedItem.quantity;
    currentStocksSelected.forEach(e => remainingQtd -= e.quantity);
  }

  return (
    <div className={styles.modal_items_body}>
      <div className={styles.tab}>
        {scheduleProducedureItems.usedItems.map(item => (
          <span
            className={tabInfo === item.id ? styles.elementActive : styles.element}
            onClick={() => setTabInfo(item.id)}
          >
            {item.item_name}
          </span>
        ))}
      </div>
      {stock == null ? (
        <div align="center" style={{marginBottom: '30px'}}>
          <CircularProgress color="success" size={40} />
        </div>
      ) : (
        <>
          {!isReadOnly && (
            <>
              <div className={styles.remainingQtd}>
                <div>
                  <small>Qtd do insumo utilizado</small>
                  <Input
                    name="remainingQtd"
                    value={selectedItem.quantity}
                    placeholder="Qtd"
                    disabled={isReadOnly}
                    inputClassName={styles.input_qtd}
                    onChange={e => {
                      selectedItem.quantity = e.target.value.replace(/\D/g, '');
                      setScheduleProducedureItems({
                        ...scheduleProducedureItems,
                        usedItems: [ ...scheduleProducedureItems.usedItems ]
                      });
                    }}
                  />
                </div>
                <small>Ainda faltam informar {remainingQtd} unidades.</small>
              </div>
              <Grid container spacing={1} className={styles.inputs}>
                <Grid item xs={7}>
                  <Autocomplete
                    className={styles.autoComplete}
                    label="Lote do Estoque"
                    value={selectedStock}
                    options={stockOptions.sort((a, b) => a.label - b.label)}
                    onChange={(_, item) => setSelectedStock(item)}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    name="quantity"
                    value={qtdValue}
                    placeholder="Qtd p/ usar do lote"
                    inputClassName={styles.input_qtd}
                    onChange={e => setQtdValue(e.target.value)}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    size="sm"
                    label="+"
                    disabled={
                      isReadOnly ||
                      selectedStock == null || 
                      qtdValue === '' || 
                      qtdValue > remainingQtd ||
                      qtdValue > stockBatchQtd[selectedStock.value]
                    }
                    onClick={() => {
                      setScheduleProducedureItems({
                        ...scheduleProducedureItems,
                        stocksSelected: [
                          ...scheduleProducedureItems.stocksSelected,
                          {
                            item_procedure: tabInfo,
                            stock: selectedStock.value,
                            quantity: parseInt(qtdValue),
                          }
                        ]
                      });
                      setQtdValue('');
                      setSelectedStock(null);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Table
            columns={['Lote do Estoque', 'Quantidade', 'Remover']}
            lines={currentStocksSelected.map(e => [
              `Lote ${stock.find(stock => stock.id === e.stock)?.batch}`,
              e.quantity,
              <Button
                label="-"
                size="sm"
                disabled={isReadOnly}
                onClick={() => {
                  const index = scheduleProducedureItems.stocksSelected.indexOf(e);
                  scheduleProducedureItems.stocksSelected.splice(index, 1);
                  setScheduleProducedureItems({
                    ...scheduleProducedureItems,
                    stocksSelected: [ ...scheduleProducedureItems.stocksSelected ],
                  });
                }}
              />
            ])}
          />
          {currentStocksSelected.length === 0 && (
            <div className={styles.noItemsSelected}>
              {isReadOnly 
                ? 'Nenhuma quantidade foi utilizada' 
                : <>
                  Adicione os lotes dos insumos que serão<br />
                  utilizados neste atendimento no formulário acima
                </>
              }
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Prontuario;
