import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';

export const SchedulesRepository = {
  baseUrl: Endpoints.schedules,

  getSchedules: async function (clinic) {
    try {
      const { data } = await http.get(`${this.baseUrl}${`?clinic=${clinic}`}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters, limit: 100 });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  listPayments: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${Endpoints.schedulesPayments}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  countWaiting: async function (clinic, user) {
    try {
      const queryString = stringifyFilters({ clinic, user, status: 'waiting', limit: 1 });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data?.count ?? 0;
    } catch (err) {
      return parseError(err);
    }
  },
  get: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  addSchedule: async function (payload) {
    try {
      const formData = new FormData();
      for (var key in payload) {
        if (payload[key] != null) {
          formData.append(key, payload[key]);
        }
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const { data } = await http.post(`${this.baseUrl}`, formData, config);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  addScheduleFile: async function (scheduleId, payload) {
    try {
      const formData = new FormData();
      formData.append('file', payload.file);
      formData.append('filename', payload.filename);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const { data } = await http.post(`${this.baseUrl}/${scheduleId}/files`, formData, config);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  editSchedule: async function (id, payload) {
    try {
      const formData = new FormData();
      for (var key in payload) {
        if (payload[key] != null) {
          formData.append(key, payload[key]);
        }
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const { data } = await http.patch(`${this.baseUrl}/${id}`, formData, config);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getScheduleFiles: async function (scheduleId) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${scheduleId}/files/`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteScheduleFile: async function (scheduleId, fileId) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${scheduleId}/files/${fileId}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
