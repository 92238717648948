import { useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import axios from 'axios';
import { storageAccessToken } from 'shared/storage';

export const axiosBaseURL = `${process.env.REACT_APP_API_HOST}${
  process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''
}/${process.env.REACT_APP_API_BASE}`;

const instance = axios.create({
  baseURL: axiosBaseURL,
});

instance.interceptors.request.use(config => {
  const options = config;
  try {
    const accessToken = storageAccessToken();
    if (accessToken && !config.headers.authorization) options.headers.authorization = `Bearer ${accessToken}`;

    const { url } = config;
    if (!url[url.length - 1] === '/home') options.url = `${config.url}/`;
  } catch (err) {
  }
  return options;
});

instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401 && !window.location.href.includes('login')) {
      const { logout } = useContext(AuthContext);
      logout();
    }
    return Promise.reject(error);
  }
);

const defaultLanguage = localStorage.getItem('language');
const acceptLanguage = defaultLanguage === 'en' ? 'en' : 'pt-br';

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['Accept-Language'] = acceptLanguage;

export default instance;
